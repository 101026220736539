import React from "react";

import { Modal, ModalHeader, ModalBody } from "Molecules";
import { NewButton, Flex } from "Atoms";

type Props = {
    reOpen: () => void;
};

export const PunchClockModalPrompt: React.FC<Props> = ({ reOpen }) => {
    return (
        <Modal open={true} size="xs">
            <ModalHeader>Stämpelklockan hamnade i bakgrunden</ModalHeader>
            <ModalBody>
                <Flex justify="center">
                    <NewButton onClick={reOpen} themeColor="blue" size="lg">
                        Öppna den igen
                    </NewButton>
                </Flex>
            </ModalBody>
        </Modal>
    );
};
