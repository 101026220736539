import React, { lazy } from "react";
import { useLocation, Navigate } from "react-router-dom";

import {
    AwaitModalResponseProvider,
    BaxiSocketProvider,
    OnlineProvider,
    PosProvider,
    PunchClockProvider,
    SplitCashRegisterProvider,
    WasteProductProvider,
    DiscountsProvider,
    SubscriptionsProvider,
    UserTypes,
    PosSettingsProvider,
    ProductLibraryProvider
} from "Providers";
import { ConsumerFooter } from "Organisms";
import { MiniFooter } from "Molecules";
import { UserRoles } from "Constants";
import { BackOfficeRouteRequirement, IRoute } from "Types";
import { retry } from "../utils/retryLazyLoading";
import { OnlineRedirect } from "../../customer/pages/OnlineRedirect";
import RedirectErrorBoundary from "../../RedirectErrorBoundary";
import ReloadErrorBoundary from "../../ReloadErrorBoundary";
import PublicErrorBoundary from "../../PublicErrorBoundary";
import { InvalidLinkWarning } from "../../customer/pages/expressOnlineOrder/InvalidLinkWarning";
import { CustomerNavbar } from "../../customer/shared";
import { BackOfficeNavigation, BackOfficeContainer } from "../../admin/shared/components";
import { UserInfo } from "../../api/UserInfo";
import { RHTestPage } from "../../atoms/ReactHookForm/pages/RHTestPage";
import {
    QMenuAnalysisResults,
    QMenuSearchTest,
    QMenuUploadPage,
    QMenuPreviewPage
} from "../../admin/components/menu-analysis";
import { ExpressMiniFooter } from "../../customer/pages/expressOnlineOrder/ExpressMiniFooter";

const GroupOnlineOrder = lazy(() =>
    retry(
        () =>
            import(/* webpackChunkName: "GroupOnlineOrder" */ "../../customer/pages/groupOnlineOrder/GroupOnlineOrder")
    )
);

const GroupOnlineCheckout = lazy(() =>
    retry(
        () =>
            import(
                /* webpackChunkName: "GroupOnlineCheckout" */ "../../customer/pages/groupOnlineCheckout/GroupOnlineCheckout"
            )
    )
);

const GroupOnlineOrderOverview = lazy(() =>
    retry(
        () =>
            import(
                /* webpackChunkName: "GroupOnlineOrderOverview" */ "../../customer/pages/groupOnlineOrderOverview/GroupOnlineOrderOverview"
            )
    )
);

const Login = lazy(() => retry(() => import(/* webpackChunkName: "Login" */ "../../admin/components/Login")));
const CompanyUserForgotPassword = lazy(() =>
    retry(
        () =>
            import(
                /* webpackChunkName: "CompanyUserForgotPassword" */ "../../admin/components/company-user-forgot-password/CompanyUserForgotPassword"
            )
    )
);
const CompanyUserChangePassword = lazy(() =>
    retry(
        () =>
            import(
                /* webpackChunkName: "CompanyUserChangePassword" */ "../../admin/components/company-user-change-password/CompanyUserChangePassword"
            )
    )
);
const Companies = lazy(() =>
    retry(() => import(/* webpackChunkName: "Companies" */ "../../admin/components/company/Companies"))
);
const Manual = lazy(() => retry(() => import(/* webpackChunkName: "Manual" */ "../../admin/components/manual/Manual")));
const CompanySettings = lazy(() =>
    retry(
        () => import(/* webpackChunkName: "CompanySettings" */ "../../admin/components/companySettings/CompanySettings")
    )
);
const Shops = lazy(() => retry(() => import(/* webpackChunkName: "Shops" */ "../../admin/components/shops/Shops")));
const CompanyUserBenefits = lazy(() =>
    retry(
        () =>
            import(
                /* webpackChunkName: "CompanyUserBenefits" */ "../../admin/components/company-user-benefits/CompanyUserBenefits"
            )
    )
);
const CompanyUserShortcuts = lazy(() =>
    retry(
        () =>
            import(
                /* webpackChunkName: "CompanyUserShortcuts" */ "../../admin/components/company-user-shortcuts/CompanyUserShortcuts"
            )
    )
);
const ProductLibrary = React.lazy(() =>
    retry(() => import(/* webpackChunkName: "ProductLibrary" */ "../../admin/components/product/ProductLibrary"))
);
const Users = React.lazy(() =>
    retry(() => import(/* webpackChunkName: "Users" */ "../../admin/components/user/Users"))
);
const ShopSettings = React.lazy(() =>
    retry(() => import(/* webpackChunkName: "ShopSettings" */ "../../admin/components/shop-settings/ShopSettings"))
);
const OrderDisplay = lazy(() =>
    retry(() => import(/* webpackChunkName: "OrderDisplay" */ "../../admin/components/order-display/OrderDisplay"))
);
const KitchenDisplay = lazy(() =>
    retry(
        () => import(/* webpackChunkName: "KitchenDisplay" */ "../../admin/components/kitchen-display/KitchenDisplay")
    )
);
const PickupDisplay = lazy(() =>
    retry(() => import(/* webpackChunkName: "PickupDisplay" */ "../../admin/components/pickup-display/PickupDisplay"))
);
const ReleaseNotes = lazy(() =>
    retry(() => import(/* webpackChunkName: "ReleaseNotes" */ "../../admin/components/release-notes/ReleaseNotes"))
);
const Menus = lazy(() => retry(() => import(/* webpackChunkName: "Menus" */ "../../admin/components/menu/Menus")));
const InvoiceCustomers = lazy(() =>
    retry(() => import(/* webpackChunkName: "InvoiceCustomer" */ "../../admin/components/invoice/InvoiceCustomers"))
);
const Invoices = lazy(() =>
    retry(() => import(/* webpackChunkName: "Invoices" */ "../../admin/components/invoice/Invoices"))
);
const Pos = lazy(() => retry(() => import(/* webpackChunkName: "NewPos" */ "../../admin/components/poses/pos/Pos")));
const ExpressPosContainer = lazy(() =>
    retry(
        () =>
            import(
                /* webpackChunkName: "ExpressPosContainer" */ "../../admin/components/express-pos/ExpressPosContainer"
            )
    )
);
const IngredientsAdmin = lazy(() =>
    retry(() => import(/* webpackChunkName: "IngredientsAdmin" */ "../../admin/components/ingredients/Ingredients"))
);
const EditIngredientsGroup = lazy(() =>
    retry(
        () =>
            import(
                /* webpackChunkName: "EditIngredientsGroup" */ "../../admin/components/ingredients/EditIngredientsGroup"
            )
    )
);

const CreateIngredientsGroup = lazy(() =>
    retry(
        () =>
            import(
                /* webpackChunkName: "CreateIngredientsGroup" */ "../../admin/components/ingredients/CreateIngredientsGroup"
            )
    )
);

const Journal = lazy(() =>
    retry(() => import(/* webpackChunkName: "Journal" */ "../../admin/components/journal-memory/JournalMemory"))
);

const Report = lazy(() =>
    retry(() => import(/* webpackChunkName: "Report" */ "../../admin/components/reports/Reports"))
);

const PosAdmin = lazy(() =>
    retry(() => import(/* webpackChunkName: "PosAdmin" */ "../../admin/components/pos-admin/PosAdmin"))
);

const ExpressLandingPage = lazy(() =>
    retry(
        () =>
            import(
                /* webpackChunkName: "ExpressLandingPage" */ "../../admin/components/express-pos-v2/ExpressLandingPage"
            )
    )
);
const DeveloperView = lazy(() =>
    retry(() => import(/* webpackChunkName: "DeveloperView" */ "../../admin/components/developer/DeveloperView"))
);

const TerminalAdmin = lazy(() =>
    retry(() => import(/* webpackChunkName: "TerminalAdmin" */ "../../admin/components/terminal-admin/TerminalAdmin"))
);
const DiscountAdmin = lazy(() =>
    retry(() => import(/* webpackChunkName: "DiscountAdmin" */ "../../admin/components/discount-admin/DiscountAdmin"))
);

const CampaignAdmin = lazy(() =>
    retry(() => import(/* webpackChunkName: "CampaignAdmin" */ "../../admin/components/campaignAdmin/CampaignAdmin"))
);

const NewCampaign = lazy(() =>
    retry(() => import(/* webpackChunkName: "NewCampaign" */ "../../admin/components/campaignAdmin/NewCampaign"))
);

const ComboDiscountAdmin = lazy(() =>
    retry(
        () =>
            import(
                /* webpackChunkName: "ComboDiscountAdmin" */ "../../admin/components/discount-combo/ComboDiscountAdmin"
            )
    )
);
const SuperAdmin = lazy(() =>
    retry(() => import(/* webpackChunkName: "SuperAdmin" */ "../../admin/components/super-admin/SuperAdmin"))
);
const AIContentAssistantAdmin = lazy(() =>
    retry(
        () =>
            import(
                /* webpackChunkName: "AIContentAssistantAdmin" */ "../../admin/components/ai-content-assistant-admin/AIContentAssistantAdmin"
            )
    )
);
const AIContentAssistantAdminHistory = lazy(() =>
    retry(
        () =>
            import(
                /* webpackChunkName: "AIContentAssistantAdminHistory" */ "../../admin/components/ai-content-assistant-admin/AIContentAssistantAdminHistory"
            )
    )
);
const ServiceFeeAdmin = lazy(() =>
    retry(
        () =>
            import(/* webpackChunkName: "ServiceFeeAdmin" */ "../../admin/components/service-fee-admin/ServiceFeeAdmin")
    )
);
const CustomerRegistrationAdmin = lazy(() =>
    retry(
        () =>
            import(
                /* webpackChunkName: "ServiceFeeAdmin" */ "../../admin/components/customer-registration-admin/CustomerRegistrationAdmin"
            )
    )
);
const CustomerRegistrationEditPage = lazy(() =>
    retry(
        () =>
            import(
                /* webpackChunkName: "ServiceFeeAdmin" */ "../../admin/components/customer-registration-admin/CustomerRegistrationEditPage"
            )
    )
);
const GroupLandingPageAdmin = lazy(() =>
    retry(
        () =>
            import(
                /* webpackChunkName: "GroupLandingPageAdmin" */ "../../admin/components/group-landing-page-admin/GroupLandingPageAdmin"
            )
    )
);
const Changelog = lazy(() =>
    retry(() => import(/* webpackChunkName: "Changelog" */ "../../admin/components/super-admin/changelog/Changelog"))
);
const ReportDashboard = lazy(() =>
    retry(
        () =>
            import(/* webpackChunkName: "ReportDashboard" */ "../../admin/components/report-dashboards/ReportDashboard")
    )
);
const ReportDashboardV2 = lazy(() =>
    retry(
        () =>
            import(
                /* webpackChunkName: "ReportDashboardV2" */ "../../admin/components/analytics-reports/reports-dashboard/ReportDashboardV2"
            )
    )
);

const AnalyticsDashboardV2 = lazy(() =>
    retry(
        () =>
            import(
                /* webpackChunkName: "AnalyticsDashboardV2" */ "../../admin/components/analytics-reports/analytics-dashboard/AnalyticsDashboardV2"
            )
    )
);

const ReportCashInvoice = lazy(() =>
    retry(
        () =>
            import(
                /* webpackChunkName: "ReportCashInvoice" */ "../../admin/components/report-cash-invoice/ReportCashInvoice"
            )
    )
);
const PickupDisplayMobile = lazy(() =>
    retry(() => import(/* webpackChunkName: "PickupDisplayMobile" */ "../../customer/pages/pickup/PickupDisplayMobile"))
);
const StandalonePickupDisplay = lazy(() =>
    retry(
        () =>
            import(
                /* webpackChunkName: "StandalonePickupDisplay" */ "../../admin/components/pickup-display/StandalonePickupDisplay"
            )
    )
);
const OnlineOrder = lazy(() =>
    retry(() => import(/* webpackChunkName: "OnlineOrder" */ "../../customer/pages/onlineOrder/OnlineOrder"))
);
const ExpressOnlineOrder = lazy(() =>
    retry(
        () =>
            import(
                /* webpackChunkName: "ExpressOnlineOrder" */ "../../customer/pages/expressOnlineOrder/ExpressOnlineOrder"
            )
    )
);

const OnlineCheckout = lazy(() =>
    retry(
        () => import(/* webpackChunkName: "Restaurant_Checkout" */ "../../customer/pages/onlineCheckout/OnlineCheckout")
    )
);
const ExpressCustomerCheckout = lazy(() =>
    retry(
        () =>
            import(
                /* webpackChunkName: "Express_Restaurant_Checkout" */ "../../customer/pages/checkout/ExpressCustomerCheckout"
            )
    )
);
const OrderStatusContainer = lazy(() =>
    retry(
        () =>
            import(
                /* webpackChunkName: "OrderStatusContainer" */ "../../customer/pages/orderStatus/OrderStatusContainer"
            )
    )
);
const FrontPage = lazy(() =>
    retry(() => import(/* webpackChunkName: "FrontPage" */ "../../customer/pages/frontpage/FrontPage"))
);
const CustomerRegister = lazy(() =>
    retry(() => import(/* webpackChunkName: "CustomerSignup" */ "../../customer/pages/signup/CustomerRegister"))
);
const Logout = lazy(() => retry(() => import(/* webpackChunkName: "Logout" */ "../../admin/components/Logout")));
const NotFound = lazy(() => retry(() => import(/* webpackChunkName: "NotFound" */ "../../admin/components/NotFound")));
const AnalyticsDashboard = lazy(() =>
    retry(
        () =>
            import(
                /* webpackChunkName: "AnalyticsDashboard" */ "../../admin/components/analytics-dashboard/AnalyticDashboard"
            )
    )
);
const OrderHistory = lazy(() =>
    retry(() => import(/* webpackChunkName: "OrderHistory" */ "../../admin/components/receipt/OrderHistory"))
);
const ShopHome = lazy(() =>
    retry(() => import(/* webpackChunkName: "ShopHome" */ "../../customer/pages/shopHome/ShopHome"))
);
const CompanyHome = lazy(() =>
    retry(() => import(/* webpackChunkName: "CompanyHome" */ "../../customer/pages/companyHome/CompanyHome"))
);
const OnlineUrl = lazy(() =>
    retry(() => import(/* webpackChunkName: "OnlineUrl" */ "../../customer/pages/onlineUrl/OnlineUrl"))
);
const OnlineInvoices = lazy(() =>
    retry(
        () => import(/* webpackChunkName: "OnlineInvoices" */ "../../admin/components/online-invoices/OnlineInvoices")
    )
);
const PrivacyPage = lazy(() =>
    retry(() => import(/* webpackChunkName: "PrivacyPage" */ "../../customer/pages/privacyPage/PrivacyPage"))
);
const TermsAndConditions = lazy(() =>
    retry(
        () =>
            import(
                /* webpackChunkName: "TermsAndConditions" */ "../../customer/pages/termsAndConditions/TermsAndConditions"
            )
    )
);
const ViewCateringOrder = lazy(() =>
    retry(
        () =>
            import(
                /* webpackChunkName: "IncomingCateringOrder" */ "../../admin/components/view-catering-order/ViewCateringOrder"
            )
    )
);
const ViewAllCateringOrders = lazy(() =>
    retry(
        () =>
            import(
                /* webpackChunkName: "ViewAllCateringOrders" */ "../../admin/components/view-all-catering-orders/ViewAllCateringOrders"
            )
    )
);
const UserAccountRegister = lazy(() =>
    retry(
        () =>
            import(
                /* webpackChunkName: "UserAccountRegister" */ "../../customer/pages/userAccountRegister/UserAccountRegister"
            )
    )
);
const UserAccountLogin = lazy(() =>
    retry(
        () =>
            import(/* webpackChunkName: "UserAccountLogin" */ "../../customer/pages/userAccountLogin/UserAccountLogin")
    )
);
const UserAccount = lazy(() =>
    retry(() => import(/* webpackChunkName: "UserAccount" */ "../../customer/pages/userAccount/UserAccount"))
);
const ForgotPassword = lazy(() =>
    retry(() => import(/* webpackChunkName: "ForgotPassword" */ "../../customer/pages/forgotPassword/ForgotPassword"))
);
const ConfirmAccount = lazy(() =>
    retry(() => import(/* webpackChunkName: "ConfirmAccount" */ "../../customer/pages/confirmAccount/ConfirmAccount"))
);
const ChangePassword = lazy(() =>
    retry(() => import(/* webpackChunkName: "ChangePassword" */ "../../customer/pages/changePassword/ChangePassword"))
);
const SignupConfirmation = lazy(() =>
    retry(
        () =>
            import(
                /* webpackChunkName: "SignupConfirmation" */ "../../customer/pages/signupConfirmation/SignupConfirmation"
            )
    )
);
const LogoutUserAccount = lazy(() =>
    retry(
        () =>
            import(
                /* webpackChunkName: "LogoutUserAccount" */ "../../customer/pages/logoutUserAccount/LogoutUserAccount"
            )
    )
);
const FortnoxRegister = lazy(() =>
    retry(
        () =>
            import(/* webpackChunkName: "FortnoxRegister" */ "../../admin/components/fortnox-register/FortnoxRegister")
    )
);
const ViewSubscriptions = lazy(() =>
    retry(
        () =>
            import(
                /* webpackChunkName: "ViewSubscriptions" */ "../../admin/components/subscriptions/view/ViewSubscriptions"
            )
    )
);

const CreateSubscriptions = lazy(() =>
    retry(
        () =>
            import(
                /* webpackChunkName: "CreateSubscriptions" */ "../../admin/components/subscriptions/create/CreateSubscription"
            )
    )
);

const EditSubscriptions = lazy(() =>
    retry(
        () =>
            import(
                /* webpackChunkName: "EditSubscriptions" */ "../../admin/components/subscriptions/edit/EditSubscription"
            )
    )
);
const ViewGroupSubscriptions = lazy(() =>
    retry(
        () =>
            import(
                /* webpackChunkName: "ViewGroupSubscriptions" */ "../../admin/components/groupSubscriptions/view/ViewGroupSubscriptions"
            )
    )
);

const CreateGroupSubscription = lazy(() =>
    retry(
        () =>
            import(
                /* webpackChunkName: "CreateGroupSubscription" */ "../../admin/components/groupSubscriptions/create/CreateGroupSubscription"
            )
    )
);

const EditGroupSubscriptions = lazy(() =>
    retry(
        () =>
            import(
                /* webpackChunkName: "EditGroupSubscriptions" */ "../../admin/components/groupSubscriptions/edit/EditGroupSubscriptions"
            )
    )
);

const SubscriptionOverview = lazy(() =>
    retry(
        () =>
            import(
                /* webpackChunkName: "SubscriptionOverview" */ "../../admin/components/subscriptions/view/SubscriptionOverview"
            )
    )
);

const CompanySubscriptions = lazy(() =>
    retry(
        () =>
            import(
                /* webpackChunkName: "CompanySubscriptions" */ "../../customer/pages/companySubscriptions/CompanySubscriptions"
            )
    )
);
const SubscriptionStatusContainer = lazy(() =>
    retry(
        () =>
            import(
                /* webpackChunkName: "SubscriptionStatusContainer" */ "../../customer/pages/subscriptionStatusContainer/SubscriptionStatusContainer"
            )
    )
);
const SubscriptionInvitation = lazy(() =>
    retry(
        () =>
            import(
                /* webpackChunkName: "SubscriptionInvitation" */ "../../customer/pages/subscriptionInvitation/SubscriptionInvitation"
            )
    )
);

const MediaLibrary = lazy(() =>
    retry(() => /* webpackChunkName: "MediaLibrary" */ import("../../admin/components/mediaLibrary/MediaLibrary"))
);

const GiftCardStatusContainer = lazy(() =>
    retry(
        () =>
            import(
                /* webpackChunkName: "GiftCardStatusContainer" */ "../../customer/pages/giftCardStatusContainer/GiftCardStatusContainer"
            )
    )
);

const Inventory = lazy(() =>
    retry(() => /* webpackChunkName: "Inventory" */ import("../../admin/components/inventory/Inventory"))
);

const TableService = React.lazy(() =>
    retry(() => import(/* webpackChunkName: "TableService" */ "../../admin/components/table-service/TableService"))
);

const CustomerFeedback = lazy(() =>
    retry(
        () =>
            /* webpackChunkName: "CustomerFeedback" */ import("../../customer/pages/customerFeedback/CustomerFeedback")
    )
);

const CustomerFeedbackAdmin = lazy(() =>
    retry(
        () =>
            /* webpackChunkName: "CustomerFeedback back office" */ import(
                "../../admin/components/customerFeedback/CustomerFeedbackAdmin"
            )
    )
);

const CustomerOptOut = lazy(() =>
    retry(() => /* webpackChunkName: "CustomerOptOut" */ import("../../customer/pages/customerOptOut/CustomerOptOut"))
);

const RedirectToLogin: React.FC = () => {
    const location = useLocation();
    return (
        <Navigate
            to="/admin"
            state={{
                msg: "Du måste vara inloggad för att komma åt sidan",
                from: location.pathname,
                search: location.search
            }}
            replace
        />
    );
};

const FortNoxConnection = lazy(() =>
    retry(
        () =>
            /* webpackChunkName: "Fortnox Register" */ import("../../admin/components/fortnox-register/FortnoxRegister")
    )
);

const StaffManagementLogs = lazy(() =>
    retry(
        () =>
            /* webpackChunkName: "StaffManagement Logs" */ import(
                "../../admin/components/staffManagement/StaffManagementLogs"
            )
    )
);

const RedirectToUserAccountLogin: React.FC = () => {
    const location = useLocation();
    const next = location.pathname + location.search;
    return <Navigate to="/login" state={{ next }} replace />;
};

const {
    ROLE_ACCOUNTING_USER,
    ROLE_ADMIN,
    ROLE_QOPLA_SALE,
    ROLE_QOPLA_SUPPORT,
    ROLE_SUPER_ADMIN,
    ROLE_USER,
    ROLE_UMBRELLA_USER,
    ROLE_UMBRELLA_MULTI_COMPANY_ADMIN
} = UserRoles;

export default [
    {
        path: "/",
        component: FrontPage,
        exact: true,
        providers: [<PosProvider />, <OnlineProvider />]
    },
    {
        path: "/admin",
        component: Login,
        exact: true,
        footer: MiniFooter,
        title: "Qopla | Admin"
    },
    {
        path: "/admin/forgotPassword",
        component: PublicErrorBoundary,
        sentryChild: CompanyUserForgotPassword,
        exact: true,
        footer: MiniFooter
    },
    {
        path: "/admin/change-password/:token",
        component: PublicErrorBoundary,
        sentryChild: CompanyUserChangePassword,
        exact: true,
        footer: MiniFooter
    },
    {
        path: "/c/:publicId",
        component: PublicErrorBoundary,
        type: "COMPANY_PAGE",
        sentryChild: CompanyHome,
        exact: true,
        nav: CustomerNavbar
    },
    {
        path: "/pickupDisplayMobile/:publicId/:id",
        component: PublicErrorBoundary,
        type: "PICKUP_PAGE",
        sentryChild: PickupDisplayMobile
    },
    {
        path: "/standalonePickupDisplay/:publicId",
        component: PublicErrorBoundary,
        type: "PICKUP_PAGE",
        sentryChild: StandalonePickupDisplay
    },
    {
        path: "/restaurant/:name/:id",
        component: PublicErrorBoundary,
        type: "RESTAURANT_PAGE",
        sentryChild: OnlineRedirect,
        exact: true
    },
    {
        path: "/express/restaurant/:name/:id/order",
        component: InvalidLinkWarning,
        type: "ONLINE_EXPRESS_RESTAURANT_PAGE",
        providers: [<PosProvider />, <OnlineProvider />, <SubscriptionsProvider />]
    },
    {
        path: "/express/restaurant/:name/:id/:publicPOSId/order",
        component: PublicErrorBoundary,
        type: "ONLINE_EXPRESS_RESTAURANT_PAGE",
        sentryChild: ExpressOnlineOrder,
        providers: [<PosProvider />, <OnlineProvider />, <SubscriptionsProvider />]
    },
    {
        path: "/restaurant/:name/:id/order",
        component: PublicErrorBoundary,
        type: "RESTAURANT_PAGE",
        sentryChild: OnlineOrder,
        footer: ConsumerFooter,
        providers: [<PosProvider />, <OnlineProvider />, <SubscriptionsProvider />],
        nav: CustomerNavbar
    },
    {
        path: "/restaurant/:name/:id/home",
        component: PublicErrorBoundary,
        type: "RESTAURANT_HOME",
        sentryChild: ShopHome,
        providers: [<PosProvider />, <OnlineProvider />, <SubscriptionsProvider />]
    },
    {
        path: "/express/order-status/:orderId",
        component: PublicErrorBoundary,
        type: "ONLINE_EXPRESS_ORDER_STATUS",
        sentryChild: OrderStatusContainer,
        providers: [<PosProvider />, <OnlineProvider />],
        footer: ExpressMiniFooter
    },
    {
        path: "/order-status/:orderId/:tableId?/:publicShopId?/:posId?/:orderWindowId?",
        component: PublicErrorBoundary,
        type: "ORDER_STATUS",
        sentryChild: OrderStatusContainer,
        providers: [<PosProvider />, <OnlineProvider />],
        footer: MiniFooter,
        nav: CustomerNavbar
    },
    {
        path: "/express/restaurant/:name/:id/checkout",
        component: PublicErrorBoundary,
        type: "ONLINE_EXPRESS_CHECKOUT",
        sentryChild: ExpressCustomerCheckout,
        providers: [<PosProvider />, <OnlineProvider />],
        footer: ExpressMiniFooter
    },
    {
        path: "/restaurant/:name/:id/checkout",
        component: PublicErrorBoundary,
        type: "CHECKOUT",
        sentryChild: OnlineCheckout,
        footer: ConsumerFooter,
        providers: [<PosProvider />, <OnlineProvider />, <SubscriptionsProvider />],
        nav: CustomerNavbar
    },
    {
        path: "/onlineCheckout",
        component: PublicErrorBoundary,
        type: "CHECKOUT",
        sentryChild: OnlineCheckout,
        footer: MiniFooter,
        providers: [<PosProvider />, <OnlineProvider />, <SubscriptionsProvider />],
        nav: CustomerNavbar
    },
    {
        path: "/onlineCheckout",
        component: PublicErrorBoundary,
        type: "CHECKOUT",
        sentryChild: OnlineCheckout,
        footer: MiniFooter,
        providers: [<PosProvider />, <OnlineProvider />, <SubscriptionsProvider />],
        nav: CustomerNavbar
    },
    {
        path: "/restaurant/:name/:id/customerfeedback/:orderId",
        component: PublicErrorBoundary,
        type: "ONLINE_CUSTOMER_FEEDBACK",
        sentryChild: CustomerFeedback,
        footer: MiniFooter,
        nav: CustomerNavbar,
        exact: true
    },
    {
        path: "/restaurant/:name/:id/customeroptout",
        component: PublicErrorBoundary,
        type: "ONLINE_CUSTOMER_OPT_OUT",
        sentryChild: CustomerOptOut,
        footer: MiniFooter,
        nav: CustomerNavbar,
        exact: true
    },
    {
        path: "/subscriptions/:friendlySubdomainName",
        component: PublicErrorBoundary,
        sentryChild: CompanySubscriptions,
        providers: [<PosProvider />, <OnlineProvider />],
        exact: true
    },
    {
        path: "/subscription-status/:userSubscriptionId",
        component: PublicErrorBoundary,
        sentryChild: SubscriptionStatusContainer,
        footer: MiniFooter,
        providers: [<PosProvider />, <OnlineProvider />],
        nav: CustomerNavbar,
        exact: true
    },
    {
        path: "/subscription-invitation/:groupSubscriptionId",
        component: PublicErrorBoundary,
        sentryChild: SubscriptionInvitation,
        footer: MiniFooter,
        nav: CustomerNavbar,
        exact: true
    },
    {
        path: "/giftcard-status/:userGiftCardId",
        component: PublicErrorBoundary,
        sentryChild: GiftCardStatusContainer,
        footer: MiniFooter,
        providers: [<PosProvider />, <OnlineProvider />],
        nav: CustomerNavbar,
        exact: true
    },
    {
        path: "/admin/companies",
        component: Companies,
        unAuth: RedirectToLogin,
        allowedRoles: [ROLE_ADMIN, ROLE_SUPER_ADMIN],
        sideNavigation: BackOfficeNavigation,
        container: BackOfficeContainer
    },
    {
        path: "/admin/manual",
        component: Manual,
        unAuth: RedirectToLogin,
        allowedRoles: [ROLE_ADMIN, ROLE_USER, ROLE_ACCOUNTING_USER],
        sideNavigation: BackOfficeNavigation,
        container: BackOfficeContainer
    },
    {
        path: "/admin/shops",
        component: Shops,
        unAuth: RedirectToLogin,
        allowedRoles: [ROLE_USER, ROLE_ADMIN],
        sideNavigation: BackOfficeNavigation,
        container: BackOfficeContainer,
        hasToHave: [BackOfficeRouteRequirement.SELECTED_COMPANY]
    },
    {
        path: "/admin/shop/:id",
        component: ShopSettings,
        allowedRoles: [ROLE_ADMIN],
        unAuth: RedirectToLogin,
        sideNavigation: BackOfficeNavigation,
        container: BackOfficeContainer
    },
    {
        path: "/admin/company/:id",
        component: CompanySettings,
        allowedRoles: [ROLE_ADMIN],
        unAuth: RedirectToLogin,
        sideNavigation: BackOfficeNavigation,
        container: BackOfficeContainer
    },
    {
        path: "/admin/benefits",
        component: CompanyUserBenefits,
        allowedRoles: [ROLE_USER, ROLE_ADMIN],
        unAuth: RedirectToLogin,
        sideNavigation: BackOfficeNavigation,
        container: BackOfficeContainer
    },
    {
        path: "/admin/shortcuts",
        component: CompanyUserShortcuts,
        allowedRoles: [ROLE_USER, ROLE_ADMIN],
        unAuth: RedirectToLogin,
        sideNavigation: BackOfficeNavigation,
        container: BackOfficeContainer
    },
    {
        path: "/admin/invoiceCustomers",
        component: InvoiceCustomers,
        allowedRoles: [ROLE_ADMIN, ROLE_ACCOUNTING_USER],
        unAuth: RedirectToLogin,
        sideNavigation: BackOfficeNavigation,
        container: BackOfficeContainer,
        hasToHave: [BackOfficeRouteRequirement.SELECTED_COMPANY, BackOfficeRouteRequirement.SELECTED_SHOP]
    },
    {
        path: "/admin/invoices",
        component: Invoices,
        allowedRoles: [ROLE_ADMIN, ROLE_ACCOUNTING_USER],
        unAuth: RedirectToLogin,
        sideNavigation: BackOfficeNavigation,
        container: BackOfficeContainer,
        hasToHave: [BackOfficeRouteRequirement.SELECTED_COMPANY, BackOfficeRouteRequirement.SELECTED_SHOP]
    },
    {
        path: "/admin/onlineInvoices",
        component: OnlineInvoices,
        allowedRoles: [ROLE_ADMIN, ROLE_ACCOUNTING_USER, ROLE_UMBRELLA_USER],
        unAuth: RedirectToLogin,
        sideNavigation: BackOfficeNavigation,
        container: BackOfficeContainer,
        hasToHave: [BackOfficeRouteRequirement.SELECTED_COMPANY, BackOfficeRouteRequirement.SELECTED_SHOP]
    },
    {
        path: "/admin/products",
        component: ProductLibrary,
        allowedRoles: [ROLE_ADMIN],
        providers: [<ProductLibraryProvider />],
        unAuth: RedirectToLogin,
        sideNavigation: BackOfficeNavigation,
        container: BackOfficeContainer,
        hasToHave: [BackOfficeRouteRequirement.SELECTED_COMPANY]
    },
    {
        path: "/admin/mediaLibrary",
        component: MediaLibrary,
        allowedRoles: [ROLE_ADMIN],
        unAuth: RedirectToLogin,
        sideNavigation: BackOfficeNavigation,
        container: BackOfficeContainer
    },
    {
        path: "/admin/users",
        component: Users,
        allowedRoles: [ROLE_ADMIN],
        unAuth: RedirectToLogin,
        sideNavigation: BackOfficeNavigation,
        container: BackOfficeContainer,
        hasToHave: [BackOfficeRouteRequirement.SELECTED_COMPANY]
    },
    {
        path: "/admin/orderDisplay",
        component: RedirectErrorBoundary,
        type: "KDS",
        sentryChild: OrderDisplay,
        allowedRoles: [ROLE_USER, ROLE_ADMIN],
        providers: [<PunchClockProvider />],
        unAuth: RedirectToLogin,
        sideNavigation: BackOfficeNavigation,
        hasToHave: [BackOfficeRouteRequirement.SELECTED_COMPANY, BackOfficeRouteRequirement.SELECTED_SHOP]
    },
    {
        path: "/admin/view-catering-order/:orderId",
        component: RedirectErrorBoundary,
        sentryChild: ViewCateringOrder,
        allowedRoles: [ROLE_ADMIN],
        unAuth: RedirectToLogin,
        sideNavigation: BackOfficeNavigation,
        container: BackOfficeContainer
    },
    {
        path: "/admin/view-all-catering-orders",
        component: RedirectErrorBoundary,
        sentryChild: ViewAllCateringOrders,
        allowedRoles: [ROLE_ADMIN],
        unAuth: RedirectToLogin,
        sideNavigation: BackOfficeNavigation,
        container: BackOfficeContainer
    },
    {
        path: "/admin/kitchenDisplay",
        component: RedirectErrorBoundary,
        type: "KDS",
        sentryChild: KitchenDisplay,
        allowedRoles: [ROLE_USER, ROLE_ADMIN],
        unAuth: RedirectToLogin,
        sideNavigation: BackOfficeNavigation,
        hasToHave: [BackOfficeRouteRequirement.SELECTED_COMPANY, BackOfficeRouteRequirement.SELECTED_SHOP]
    },
    {
        path: "/admin/pickupDisplay",
        component: ReloadErrorBoundary,
        type: "KDS",
        sentryChild: PickupDisplay,
        allowedRoles: [ROLE_USER, ROLE_ADMIN],
        unAuth: RedirectToLogin,
        sideNavigation: BackOfficeNavigation,
        hasToHave: [BackOfficeRouteRequirement.SELECTED_COMPANY, BackOfficeRouteRequirement.SELECTED_SHOP]
    },
    {
        path: "/admin/releaseNotes",
        component: ReloadErrorBoundary,
        type: "KDS",
        sentryChild: ReleaseNotes,
        allowedRoles: [ROLE_USER, ROLE_ADMIN],
        unAuth: RedirectToLogin,
        sideNavigation: BackOfficeNavigation,
        container: BackOfficeContainer
    },
    {
        path: "/admin/menus",
        component: Menus,
        allowedRoles: [ROLE_ADMIN],
        unAuth: RedirectToLogin,
        sideNavigation: BackOfficeNavigation,
        container: BackOfficeContainer,
        hasToHave: [BackOfficeRouteRequirement.SELECTED_COMPANY]
    },
    {
        path: "/admin/receiptCopies",
        component: OrderHistory,
        allowedRoles: [ROLE_ADMIN, ROLE_SUPER_ADMIN, ROLE_ACCOUNTING_USER, ROLE_UMBRELLA_USER],
        unAuth: RedirectToLogin,
        sideNavigation: BackOfficeNavigation,
        container: BackOfficeContainer,
        hasToHave: [BackOfficeRouteRequirement.SELECTED_SHOP]
    },
    {
        path: "/admin/staffManagementLogs",
        component: StaffManagementLogs,
        allowedRoles: [ROLE_SUPER_ADMIN],
        unAuth: RedirectToLogin,
        sideNavigation: BackOfficeNavigation,
        container: BackOfficeContainer,
        hasToHave: [BackOfficeRouteRequirement.SELECTED_COMPANY]
    },
    {
        path: "/admin/pos",
        component: RedirectErrorBoundary,
        sentryChild: Pos,
        type: "POS",
        allowedRoles: [ROLE_USER, ROLE_ADMIN],
        unAuth: RedirectToLogin,
        providers: [
            <PosSettingsProvider />,
            <PosProvider />,
            <DiscountsProvider />,
            <WasteProductProvider />,
            <SplitCashRegisterProvider />,
            <BaxiSocketProvider />,
            <AwaitModalResponseProvider />,
            <PunchClockProvider />
        ],
        sideNavigation: BackOfficeNavigation,
        hasToHave: [BackOfficeRouteRequirement.SELECTED_COMPANY, BackOfficeRouteRequirement.SELECTED_SHOP]
    },
    {
        path: "/admin/expressCheckout",
        component: ReloadErrorBoundary,
        sentryChild: ExpressPosContainer,
        type: "POS",
        allowedRoles: [ROLE_USER, ROLE_ADMIN],
        unAuth: RedirectToLogin
    },
    {
        path: "/admin/expressPosContainer",
        component: ReloadErrorBoundary,
        sentryChild: ExpressPosContainer,
        type: "POS",
        allowedRoles: [ROLE_USER, ROLE_ADMIN],
        unAuth: RedirectToLogin,
        providers: [<PosSettingsProvider />, <BaxiSocketProvider />],
        hasToHave: [BackOfficeRouteRequirement.SELECTED_COMPANY, BackOfficeRouteRequirement.SELECTED_SHOP]
    },
    {
        path: "/admin/expressAdmin",
        component: PosAdmin,
        allowedRoles: [ROLE_ADMIN],
        unAuth: RedirectToLogin,
        sideNavigation: BackOfficeNavigation,
        container: BackOfficeContainer,
        hasToHave: [BackOfficeRouteRequirement.SELECTED_COMPANY, BackOfficeRouteRequirement.SELECTED_SHOP]
    },
    {
        path: "/admin/ExpressLandingPage",
        component: ExpressLandingPage,
        allowedRoles: [ROLE_USER, ROLE_ADMIN],
        unAuth: RedirectToLogin,
        hasToHave: [BackOfficeRouteRequirement.SELECTED_COMPANY, BackOfficeRouteRequirement.SELECTED_SHOP],
        providers: [<PosSettingsProvider />, <BaxiSocketProvider />, <PosProvider />, <OnlineProvider />]
    },
    {
        path: "/admin/components",
        component: DeveloperView,
        allowedRoles: [ROLE_SUPER_ADMIN],
        unAuth: RedirectToLogin,
        container: BackOfficeContainer,
        sideNavigation: BackOfficeNavigation,
        hasToHave: [BackOfficeRouteRequirement.SELECTED_COMPANY, BackOfficeRouteRequirement.SELECTED_SHOP]
    },
    {
        path: "/admin/ingredients",
        exact: true,
        component: IngredientsAdmin,
        allowedRoles: [ROLE_ADMIN],
        unAuth: RedirectToLogin,
        sideNavigation: BackOfficeNavigation,
        container: BackOfficeContainer
    },
    {
        path: "/admin/ingredient/:id/edit",
        exact: true,
        component: EditIngredientsGroup,
        allowedRoles: [ROLE_ADMIN],
        unAuth: RedirectToLogin,
        sideNavigation: BackOfficeNavigation,
        container: BackOfficeContainer,
        hasToHave: [BackOfficeRouteRequirement.SELECTED_COMPANY]
    },
    {
        path: "/admin/ingredients/new",
        component: CreateIngredientsGroup,
        allowedRoles: [ROLE_ADMIN],
        unAuth: RedirectToLogin,
        sideNavigation: BackOfficeNavigation,
        container: BackOfficeContainer,
        hasToHave: [BackOfficeRouteRequirement.SELECTED_COMPANY]
    },
    {
        path: "/admin/inventory",
        component: Inventory,
        allowedRoles: [ROLE_ADMIN],
        unAuth: RedirectToLogin,
        sideNavigation: BackOfficeNavigation,
        container: BackOfficeContainer,
        hasToHave: [BackOfficeRouteRequirement.SELECTED_COMPANY]
    },
    {
        path: "/admin/journal",
        component: Journal,
        allowedRoles: [ROLE_ADMIN],
        unAuth: RedirectToLogin,
        sideNavigation: BackOfficeNavigation,
        container: BackOfficeContainer,
        hasToHave: [BackOfficeRouteRequirement.SELECTED_COMPANY]
    },
    {
        path: "/admin/report/:reportType",
        component: Report,
        allowedRoles: [ROLE_ADMIN, ROLE_ACCOUNTING_USER],
        unAuth: RedirectToLogin,
        sideNavigation: BackOfficeNavigation,
        container: BackOfficeContainer,
        hasToHave: [BackOfficeRouteRequirement.SELECTED_COMPANY, BackOfficeRouteRequirement.SELECTED_SHOP]
    },
    {
        path: "/admin/tableService",
        component: TableService,
        allowedRoles: [ROLE_ADMIN],
        unAuth: RedirectToLogin
    },

    {
        path: "/admin/posAdmin",
        component: PosAdmin,
        allowedRoles: [ROLE_ADMIN],
        unAuth: RedirectToLogin,
        sideNavigation: BackOfficeNavigation,
        container: BackOfficeContainer,
        hasToHave: [BackOfficeRouteRequirement.SELECTED_COMPANY, BackOfficeRouteRequirement.SELECTED_SHOP]
    },
    {
        path: "/admin/terminalAdmin",
        component: TerminalAdmin,
        allowedRoles: [ROLE_ADMIN],
        unAuth: RedirectToLogin,
        sideNavigation: BackOfficeNavigation,
        container: BackOfficeContainer,
        hasToHave: [BackOfficeRouteRequirement.SELECTED_COMPANY, BackOfficeRouteRequirement.SELECTED_SHOP]
    },
    {
        path: "/admin/discountAdmin/:discountType",
        component: DiscountAdmin,
        allowedRoles: [ROLE_ADMIN],
        unAuth: RedirectToLogin,
        sideNavigation: BackOfficeNavigation,
        container: BackOfficeContainer,
        hasToHave: [BackOfficeRouteRequirement.SELECTED_COMPANY]
    },
    {
        path: "/admin/campaignAdmin",
        component: CampaignAdmin,
        allowedRoles: [ROLE_ADMIN],
        unAuth: RedirectToLogin,
        sideNavigation: BackOfficeNavigation,
        container: BackOfficeContainer,
        hasToHave: [BackOfficeRouteRequirement.SELECTED_COMPANY]
    },
    {
        path: "/admin/campaign/new",
        component: NewCampaign,
        allowedRoles: [ROLE_ADMIN],
        unAuth: RedirectToLogin,
        sideNavigation: BackOfficeNavigation,
        container: BackOfficeContainer,
        hasToHave: [BackOfficeRouteRequirement.SELECTED_COMPANY]
    },
    {
        path: "/admin/reportDashboard/legacyV1",
        exact: true,
        component: ReportDashboard,
        allowedRoles: [ROLE_ADMIN, ROLE_ACCOUNTING_USER, ROLE_UMBRELLA_USER],
        unAuth: RedirectToLogin,
        sideNavigation: BackOfficeNavigation,
        container: BackOfficeContainer,
        hasToHave: [BackOfficeRouteRequirement.SELECTED_COMPANY, BackOfficeRouteRequirement.SELECTED_SHOP]
    },
    {
        path: "/admin/reportDashboard/legacy",
        exact: true,
        component: ReportDashboardV2,
        allowedRoles: [ROLE_ADMIN, ROLE_ACCOUNTING_USER, ROLE_UMBRELLA_USER],
        unAuth: RedirectToLogin,
        sideNavigation: BackOfficeNavigation,
        container: BackOfficeContainer,
        hasToHave: [BackOfficeRouteRequirement.SELECTED_SHOP]
    },
    {
        path: "/admin/analyticsDashboard/legacy",
        exact: true,
        component: AnalyticsDashboardV2,
        allowedRoles: [ROLE_ADMIN, ROLE_ACCOUNTING_USER, ROLE_UMBRELLA_USER],
        unAuth: RedirectToLogin,
        sideNavigation: BackOfficeNavigation,
        container: BackOfficeContainer,
        hasToHave: [BackOfficeRouteRequirement.SELECTED_SHOP]
    },
    {
        path: "/admin/reportDashboard",
        exact: true,
        component: ReportDashboardV2,
        allowedRoles: [ROLE_ADMIN, ROLE_ACCOUNTING_USER, ROLE_UMBRELLA_USER],
        unAuth: RedirectToLogin,
        sideNavigation: BackOfficeNavigation,
        container: BackOfficeContainer,
        hasToHave: [BackOfficeRouteRequirement.SELECTED_SHOP]
    },
    {
        path: "/admin/analyticsDashboard",
        exact: true,
        component: AnalyticsDashboardV2,
        allowedRoles: [ROLE_ADMIN, ROLE_ACCOUNTING_USER, ROLE_UMBRELLA_USER],
        unAuth: RedirectToLogin,
        sideNavigation: BackOfficeNavigation,
        container: BackOfficeContainer,
        hasToHave: [BackOfficeRouteRequirement.SELECTED_SHOP]
    },
    {
        path: "/admin/discountCombos",
        component: ComboDiscountAdmin,
        allowedRoles: [ROLE_ADMIN],
        unAuth: RedirectToLogin,
        sideNavigation: BackOfficeNavigation,
        container: BackOfficeContainer,
        hasToHave: [BackOfficeRouteRequirement.SELECTED_COMPANY]
    },
    {
        path: "/admin/fortnoxRegister",
        component: FortNoxConnection,
        allowedRoles: [ROLE_ADMIN, ROLE_ACCOUNTING_USER],
        unAuth: RedirectToLogin,
        sideNavigation: BackOfficeNavigation,
        container: BackOfficeContainer,
        hasToHave: [BackOfficeRouteRequirement.SELECTED_COMPANY]
    },
    {
        path: "/admin/reportCashInvoice",
        component: ReportCashInvoice,
        allowedRoles: [ROLE_ADMIN, ROLE_ACCOUNTING_USER],
        unAuth: RedirectToLogin,
        sideNavigation: BackOfficeNavigation,
        container: BackOfficeContainer,
        hasToHave: [BackOfficeRouteRequirement.SELECTED_SHOP]
    },
    {
        path: "/admin/superAdmin",
        component: SuperAdmin,
        allowedRoles: [ROLE_SUPER_ADMIN, ROLE_QOPLA_SALE, ROLE_QOPLA_SUPPORT],
        unAuth: RedirectToLogin,
        sideNavigation: BackOfficeNavigation,
        container: BackOfficeContainer
    },
    {
        path: "/admin/AIContentAssistantAdmin",
        component: AIContentAssistantAdmin,
        allowedRoles: [ROLE_SUPER_ADMIN, ROLE_QOPLA_SALE, ROLE_QOPLA_SUPPORT],
        unAuth: RedirectToLogin,
        sideNavigation: BackOfficeNavigation,
        container: BackOfficeContainer,
        exact: true
    },
    {
        path: "/admin/AIContentAssistantAdmin/history",
        component: AIContentAssistantAdminHistory,
        allowedRoles: [ROLE_SUPER_ADMIN, ROLE_QOPLA_SALE, ROLE_QOPLA_SUPPORT],
        unAuth: RedirectToLogin,
        sideNavigation: BackOfficeNavigation,
        container: BackOfficeContainer
    },
    {
        path: "/admin/serviceFeeAdmin",
        component: ServiceFeeAdmin,
        allowedRoles: [ROLE_SUPER_ADMIN],
        unAuth: RedirectToLogin,
        sideNavigation: BackOfficeNavigation,
        container: BackOfficeContainer
    },
    {
        path: "/admin/customerRegistrationAdmin",
        component: CustomerRegistrationAdmin,
        allowedRoles: [ROLE_SUPER_ADMIN, ROLE_QOPLA_SALE, ROLE_QOPLA_SUPPORT],
        unAuth: RedirectToLogin,
        sideNavigation: BackOfficeNavigation,
        container: BackOfficeContainer
    },
    {
        path: "/customerRegistrations/:customerRegistrationId",
        type: "CUSTOMER_REGISTRATION_EDIT_PATE",
        component: PublicErrorBoundary,
        sentryChild: CustomerRegistrationEditPage
    },
    {
        path: "/admin/groupLandingPageAdmin",
        component: GroupLandingPageAdmin,
        allowedRoles: [ROLE_SUPER_ADMIN, ROLE_QOPLA_SUPPORT],
        unAuth: RedirectToLogin,
        sideNavigation: BackOfficeNavigation,
        container: BackOfficeContainer
    },
    {
        path: "/admin/changelog",
        component: Changelog,
        allowedRoles: [ROLE_SUPER_ADMIN, ROLE_QOPLA_SUPPORT],
        unAuth: RedirectToLogin,
        sideNavigation: BackOfficeNavigation,
        container: BackOfficeContainer
    },
    {
        path: "/admin/fortnoxRegister",
        allowedRoles: [ROLE_ADMIN, ROLE_SUPER_ADMIN],
        component: FortnoxRegister,
        unAuth: RedirectToLogin,
        sideNavigation: BackOfficeNavigation,
        container: BackOfficeContainer
    },
    {
        path: "/admin/subscriptions",
        allowedRoles: [ROLE_ADMIN, ROLE_SUPER_ADMIN],
        component: ViewSubscriptions,
        unAuth: RedirectToLogin,
        exact: true,
        sideNavigation: BackOfficeNavigation,
        container: BackOfficeContainer
    },
    {
        path: "/admin/subscriptions/overview",
        allowedRoles: [ROLE_ADMIN, ROLE_SUPER_ADMIN, ROLE_QOPLA_SUPPORT],
        component: SubscriptionOverview,
        unAuth: RedirectToLogin,
        exact: true,
        sideNavigation: BackOfficeNavigation,
        container: BackOfficeContainer
    },
    {
        path: "/admin/subscriptions/create",
        allowedRoles: [ROLE_ADMIN, ROLE_SUPER_ADMIN],
        component: CreateSubscriptions,
        unAuth: RedirectToLogin,
        exact: true,
        sideNavigation: BackOfficeNavigation,
        container: BackOfficeContainer
    },
    {
        path: "/admin/subscriptions/edit/:id",
        allowedRoles: [ROLE_ADMIN, ROLE_SUPER_ADMIN],
        component: EditSubscriptions,
        unAuth: RedirectToLogin,
        exact: true,
        sideNavigation: BackOfficeNavigation,
        container: BackOfficeContainer
    },
    {
        path: "/admin/groupSubscriptions",
        allowedRoles: [ROLE_ADMIN, ROLE_SUPER_ADMIN],
        component: ViewGroupSubscriptions,
        unAuth: RedirectToLogin,
        exact: true,
        sideNavigation: BackOfficeNavigation,
        container: BackOfficeContainer
    },
    {
        path: "/admin/groupSubscriptions/create",
        allowedRoles: [ROLE_ADMIN, ROLE_SUPER_ADMIN],
        component: CreateGroupSubscription,
        unAuth: RedirectToLogin,
        exact: true,
        sideNavigation: BackOfficeNavigation,
        container: BackOfficeContainer
    },
    {
        path: "/admin/groupSubscriptions/edit/:id",
        allowedRoles: [ROLE_ADMIN, ROLE_SUPER_ADMIN],
        component: EditGroupSubscriptions,
        unAuth: RedirectToLogin,
        exact: true,
        sideNavigation: BackOfficeNavigation,
        container: BackOfficeContainer
    },
    {
        path: "/admin/customerFeedback",
        component: CustomerFeedbackAdmin,
        unAuth: RedirectToLogin,
        allowedRoles: [ROLE_ADMIN, ROLE_SUPER_ADMIN, ROLE_ACCOUNTING_USER],
        sideNavigation: BackOfficeNavigation,
        container: BackOfficeContainer,
        hasToHave: [BackOfficeRouteRequirement.SELECTED_COMPANY]
    },
    {
        path: "/privacy",
        component: PrivacyPage,
        footer: ConsumerFooter
    },
    {
        path: "/terms-and-conditions",
        component: TermsAndConditions,
        footer: ConsumerFooter
    },
    {
        path: "/register/:companyId",
        component: CustomerRegister,
        footer: ConsumerFooter
    },
    {
        path: "/logout",
        component: Logout
    },
    {
        path: "/logout-user-account",
        component: LogoutUserAccount
    },
    {
        path: "/notFound",
        component: NotFound
    },
    {
        path: "/admin/notFound",
        component: NotFound
    },
    {
        path: "/signup",
        component: PublicErrorBoundary,
        sentryChild: UserAccountRegister,
        footer: ConsumerFooter,
        nav: CustomerNavbar
    },
    {
        path: "/login",
        component: PublicErrorBoundary,
        sentryChild: UserAccountLogin,
        footer: ConsumerFooter,
        nav: CustomerNavbar
    },
    {
        path: "/api/userinfo",
        component: UserInfo
    },
    {
        path: "/group-online-order/:tableId/:publicShopId/:posId",
        exact: true,
        component: PublicErrorBoundary,
        sentryChild: GroupOnlineOrder,
        providers: [<PosProvider />, <OnlineProvider />, <SubscriptionsProvider />]
    },
    {
        path: "/RHFTest",
        component: RHTestPage
    },
    {
        path: "/qmenu/search",
        component: QMenuSearchTest
    },
    {
        path: "/qmenu/upload",
        component: QMenuUploadPage,
        exact: true
    },
    {
        path: "/qmenu/analysis/results",
        component: QMenuAnalysisResults,
        providers: [<OnlineProvider />]
    },
    {
        path: "/qmenu/preview",
        component: QMenuPreviewPage,
        providers: [<OnlineProvider />]
    },
    {
        path: "/group-online-order/:tableId/:publicShopId/:posId/checkout",
        exact: true,
        component: PublicErrorBoundary,
        sentryChild: GroupOnlineCheckout,
        providers: [<PosProvider />, <OnlineProvider />, <SubscriptionsProvider />, <SplitCashRegisterProvider />]
    },
    {
        path: "/group-online-order/:tableId/:publicShopId/:posId/order-overview/:orderWindowId",
        exact: true,
        component: PublicErrorBoundary,
        sentryChild: GroupOnlineOrderOverview,
        providers: [<PosProvider />, <OnlineProvider />, <SubscriptionsProvider />]
    },
    {
        path: "/account",
        component: PublicErrorBoundary,
        sentryChild: UserAccount,
        footer: ConsumerFooter,
        nav: CustomerNavbar,
        allowedRoles: [UserRoles.USER_ACCOUNT],
        unAuth: RedirectToUserAccountLogin,
        authUserType: UserTypes.USER_ACCOUNT
    },
    {
        path: "/forgot-password",
        component: PublicErrorBoundary,
        sentryChild: ForgotPassword,
        footer: ConsumerFooter,
        nav: CustomerNavbar
    },
    {
        path: "/confirm-account/:token",
        component: PublicErrorBoundary,
        sentryChild: ConfirmAccount,
        footer: ConsumerFooter,
        nav: CustomerNavbar
    },
    {
        path: "/change-password/:token",
        component: PublicErrorBoundary,
        sentryChild: ChangePassword,
        footer: ConsumerFooter,
        nav: CustomerNavbar
    },
    {
        path: "/signup-confirmation",
        component: PublicErrorBoundary,
        sentryChild: SignupConfirmation,
        footer: ConsumerFooter,
        nav: CustomerNavbar
    },
    {
        path: "/:url",
        component: OnlineUrl
    }
] as IRoute[];
