import React, { Suspense, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from "react-router-dom";

import routes from "./routes";
import { IRoute } from "Types";
import NotFound from "../../admin/components/NotFound";
import { CustomRoute } from "./CustomRoute";
import { LoadingMessage } from "Atoms";

const RoutingProvider: React.FC<{}> = ({}) => {
    const createdElements = createRoutesFromElements(
        <>
            {routes.map((route: IRoute) => {
                return <Route key={route.path} path={route.path} element={<CustomRoute route={route} />} />;
            })}
            <Route path="*" element={<NotFound />} />
        </>
    );

    const createdCustomRoutes = createBrowserRouter(createdElements);

    return (
        <Suspense fallback={<LoadingMessage />}>
            <RouterProvider router={createdCustomRoutes} fallbackElement={<LoadingMessage />} />
        </Suspense>
    );
};

export default RoutingProvider;
