import React, { PropsWithChildren, useEffect, useState } from "react";

import { useInterval, useMothershipLazyQuery } from "Hooks";
import { GET_FRONTEND_VERSION } from "GraphQLQueries";
import { PersistConstants, ONE_DAY } from "Constants";
import { AuthenticatedUser, SelectedCompany, SelectedTerminal, SelectedPos, Shop } from "Types";
import { getPersistedObject, persistToStorage } from "Utils";
import { usePosStore, useQoplaStore } from "Stores";
import { PosTypes } from "Constants";

const ROUTES_TO_EXCLUDE = [
    "/admin/pos",
    "/admin/kitchenDisplay",
    "/admin/orderDisplay",
    "/admin/pickupDisplay",
    "/admin/expressCheckout",
    "/admin/expressPosContainer"
];
const checkPathNeedsToUpdate = (route: string) => {
    return route.startsWith("/admin/") && !ROUTES_TO_EXCLUDE.includes(route);
};

// Multistate.
type MultiState = {
    authenticatedUser: AuthenticatedUser;
    userSessionTTL: string | null;
};

export type GlobalQoplaObject = {
    allShopsClosed: {
        enabled: boolean;
    };
    systemMessage: {
        enabled: boolean;
        messageId: string;
        message?: {
            title?: string;
            text?: string;
            type: "GLOBAL" | "TARGETED";
            level: "INFO" | "WARNING" | "SUCCESS" | "ERROR";
        };
    };
};

type MultiStateKeys = PersistConstants.AUTHENTICATED_USER | PersistConstants.USER_SESSION_TTL;
type MultiStateValues = AuthenticatedUser | number;

export interface IQoplaContext extends MultiState {
    selectedTerminal: SelectedTerminal | null;
    globalQoplaObject: GlobalQoplaObject;
    selectedShop: Shop | null;
    selectedCompany: SelectedCompany;
    selectedPos: SelectedPos | null;
    selectedPosType: PosTypes | null;
    needToRefresh: boolean;
    setSelectedUser: (value: MultiStateValues, cb?: () => void) => void;
    setSelectedShop: (shop: Shop | null) => void;
    setSelectedCompany: (shop: SelectedCompany) => void;
    setUserSessionTTL: (value: MultiStateValues, cb?: () => void) => void;
    setSelectedCompanyAndShop: (selectedCompany: SelectedCompany, shop: Shop | null) => void;
    setSelectedTerminal: (selectedTerminal: SelectedTerminal | null) => void;
    setSelectedPos: (selectedPos: SelectedPos | null) => void;
    handleSetSelectedPos: (selectedPos: SelectedPos | null) => void;
    logout: () => void;
    setGlobalQoplaObject: React.Dispatch<React.SetStateAction<GlobalQoplaObject>>;
    handleSetSelectedPosType: (posType: PosTypes | null) => void;
}

export const QoplaContext = React.createContext<IQoplaContext>(null as any);

export const QoplaProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const qoplaStore = useQoplaStore();
    const { selectedTerminal, setSelectedTerminal, selectedPos, setSelectedPos, selectedPosType, setSelectedPosType } =
        usePosStore();

    const { selectedShop, selectedCompany, setSelectedShop, setSelectedCompany, setSelectedCompanyAndShop } =
        qoplaStore;

    const [multiState, setMultiState] = useState<MultiState>(() => {
        return {
            authenticatedUser: getPersistedObject(PersistConstants.AUTHENTICATED_USER),
            userSessionTTL: getPersistedObject(PersistConstants.USER_SESSION_TTL)
        };
    });

    const [globalQoplaObject, setGlobalQoplaObject] = useState<GlobalQoplaObject>({
        allShopsClosed: {
            enabled: false
        },
        systemMessage: {
            enabled: false,
            messageId: ""
        }
    });

    const [frontendVersion, setFrontendVersion] = useState<string>("");
    const [needToRefresh, setNeedToRefresh] = useState<boolean>(false);

    const [loadNewFrontendVersion, { data: getVersion }] = useMothershipLazyQuery(GET_FRONTEND_VERSION);

    useEffect(() => {
        setSelectedPos(getPersistedObject(PersistConstants.SELECTED_POS));
        setSelectedTerminal(getPersistedObject(PersistConstants.SELECTED_TERMINAL));
        const persistedShop = getPersistedObject(PersistConstants.SELECTED_SHOP);
        // This is to support the possibility that we store the session storage selectedShop as a
        // SelectedShop type. We don't want that anymore.  Only `Shop`
        setSelectedShop(!!persistedShop?.shop ? persistedShop?.shop : persistedShop);
        setSelectedCompany(getPersistedObject(PersistConstants.SELECTED_COMPANY));

        // This dependency on `multiState.authenticatedUser` allows us to populate from the session store after login
    }, [multiState.authenticatedUser]);

    useEffect(() => {
        if (checkPathNeedsToUpdate(location.pathname)) {
            loadNewFrontendVersion();
        }
    }, []);

    useEffect(() => {
        if (getVersion) {
            if (!frontendVersion || frontendVersion === getVersion.getFrontendVersion) {
                setFrontendVersion(getVersion.getFrontendVersion);
            } else if (frontendVersion && frontendVersion !== getVersion.getFrontendVersion) {
                setNeedToRefresh(true);
            }
        }
    }, [getVersion]);

    useInterval(() => {
        if (checkPathNeedsToUpdate(window.location.pathname)) {
            loadNewFrontendVersion();
        }
    }, ONE_DAY);

    const setStateWithKey = (key: MultiStateKeys) => (value: MultiStateValues, cb?: () => void) => {
        let _value = value;

        persistToStorage(key, _value);
        setMultiState(curr => ({
            ...curr,
            [key]: _value
        }));

        !!cb && cb();
    };

    const setSelectedUser = setStateWithKey(PersistConstants.AUTHENTICATED_USER);
    const setUserSessionTTL = setStateWithKey(PersistConstants.USER_SESSION_TTL);

    const resetMultiState = () => {
        setMultiState({
            authenticatedUser: {
                companyId: "",
                fullName: "",
                roles: [],
                shopIds: [],
                token: null,
                userId: null,
                username: null,
                umbrellaCompanyIds: []
            },
            userSessionTTL: null
        });
    };
    const logout = () => {
        sessionStorage.clear();
        resetMultiState();
    };

    const value = {
        ...multiState,
        selectedTerminal,
        globalQoplaObject,
        selectedShop,
        selectedCompany,
        selectedPos,
        selectedPosType,
        needToRefresh,
        setSelectedTerminal,
        setSelectedPos,
        setSelectedUser,
        setSelectedShop,
        setSelectedCompany,
        setUserSessionTTL,
        setSelectedCompanyAndShop,
        resetState: resetMultiState,
        logout,
        setGlobalQoplaObject,
        handleSetSelectedPos: setSelectedPos,
        handleSetSelectedPosType: setSelectedPosType
    };

    return <QoplaContext.Provider value={value}>{children}</QoplaContext.Provider>;
};

export const QoplaConsumer = QoplaContext.Consumer;

export const withQopla = (Component: any) => (props: any) =>
    <QoplaConsumer>{state => <Component {...props} qoplaStore={state} />}</QoplaConsumer>;

export const useQopla = () => {
    const ctx = React.useContext(QoplaContext);
    if (!ctx) {
        throw new Error("useQopla must be within QoplaContext");
    }
    return ctx;
};
