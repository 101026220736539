import { AuthenticatedUser, ErrorMetaData, SelectedValues, UserAccount, UserTypes, IRoute } from "Types";
import { isAuthorized } from "Utils";

export const isAdminRoute = (route: IRoute) => route.path.includes("admin");
export const isExpressRoute = (route: IRoute) => route.path.includes("/express/restaurant");
export const isAccountRoute = (route: IRoute) => route.path.includes("/account");

export const buildErrorMetadata = (selectedValues: any): ErrorMetaData => ({
    company: !!selectedValues.selectedCompany ? selectedValues.selectedCompany.name : null,
    shop: !!(selectedValues.selectedShop || {}).shop ? selectedValues.selectedShop.name : null,
    pos: !!selectedValues.selectedPos ? selectedValues.selectedPos.name : null,
    terminal: !!selectedValues.selectedTerminal ? selectedValues.selectedTerminal.name : null
});

export const canAccessProtectedRoute = (
    route: IRoute,
    authenticatedUser: AuthenticatedUser,
    userAccount: UserAccount | null
) => {
    const isProtectedRoute = !!route.unAuth;

    if (isProtectedRoute) {
        let canAccessProtectedRoute = false;
        // CompanyUser has role based protection for routes and user accounts has not
        if (route.authUserType === UserTypes.USER_ACCOUNT) {
            canAccessProtectedRoute = !!userAccount;
        } else {
            canAccessProtectedRoute = isAuthorized(authenticatedUser.roles, route.allowedRoles);
        }

        return canAccessProtectedRoute;
    }
};

export const getRequirementForRoute = (route: IRoute, selectedValues: SelectedValues) => {
    const _isAdminRoute = isAdminRoute(route);

    let promptVariant = "";
    if (_isAdminRoute && !!route.hasToHave?.length) {
        const { hasToHave } = route;
        hasToHave.forEach(requirement => {
            const meetRequirement = !!selectedValues[requirement]?.id;

            promptVariant = !meetRequirement ? requirement : "";
        });
    }

    return promptVariant;
};
