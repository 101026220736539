import React, { createContext, PropsWithChildren } from "react";
import { delay } from "lodash";

import { useSwitch } from "Hooks";
import { PunchClockModalPrompt, PunchClockPage } from "./components";

interface IPunchClockContext {
    isPunchClockOpen: boolean;
    onOpenPunchClock: () => void;
}

const PunchClockContext = createContext<IPunchClockContext | null>(null);

const PunchClockProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { open: isOpen, onClose, onOpen } = useSwitch();

    const value = {
        isPunchClockOpen: isOpen,
        onOpenPunchClock: onOpen
    };

    const reOpen = () => {
        onClose();
        delay(onOpen, 200);
    };

    return (
        <PunchClockContext.Provider value={value}>
            <>
                {children}
                {isOpen && (
                    <>
                        <PunchClockPage newWindowUnloaded={onClose} />
                        <PunchClockModalPrompt reOpen={reOpen} />
                    </>
                )}
            </>
        </PunchClockContext.Provider>
    );
};

const usePunchClock = () => {
    const ctx = React.useContext(PunchClockContext);
    if (!ctx) {
        throw new Error("usePunchClock must be within a PunchClockProvider");
    }
    return ctx;
};

export { PunchClockContext, PunchClockProvider, usePunchClock };
