import { DISCOUNT_TYPE, FixedDiscount, FixedDiscounts, Subscriptions } from "Types";

export const transformSubscriptionsToFixedDiscounts = (subscriptions: Subscriptions) => {
    let subscriptionDiscounts: FixedDiscounts = [];

    for (let subscription of subscriptions) {
        const _subscriptionDiscounts = subscription.subscriptionProducts.reduce<FixedDiscounts>((acc, next) => {
            const type = next.percentageDiscount ? "rate" : "amount";
            const amount = next.percentageDiscount ? next.percentageDiscount : next.amountDiscount;

            const found = acc.findIndex(x => x.rate === amount || x.amount === amount);

            if (found === -1) {
                acc.push({
                    id: subscription.id,
                    fixedDiscount: true,
                    name: subscription.name,
                    [type]: amount,
                    menuCategoryAndProductIds: [{ productIds: [next.refProductId] }],
                    type: DISCOUNT_TYPE.SUBSCRIPTION_DISCOUNT,
                    isFixedDiscountActive: true,
                    canCombineDiscounts: false,
                    productIds: [],
                    categoryIds: [],
                    combineWithOtherDiscounts: false
                } as FixedDiscount);
            } else {
                acc[found] = {
                    ...acc[found],
                    menuCategoryAndProductIds: acc[found].menuCategoryAndProductIds.map((item: any) => ({
                        ...item,
                        productIds: [...item.productIds, next.refProductId]
                    }))
                };
            }

            return acc;
        }, []);

        subscriptionDiscounts = subscriptionDiscounts.concat(_subscriptionDiscounts);
    }

    return subscriptionDiscounts;
};
