import { calculateProductPrice } from "../../../admin/components/poses/pos/utils";
import { updateCombinedDiscountsAndOtherDiscounts } from "../../../admin/components/poses/shared/utils/cartController";
import { CartIdAndQuantity, CartProduct, OrderWindow, SplitOrderWindowDiscounts } from "Types";

export const updateSplitProductPrices = (cartProducts: CartProduct[]) => {
    return cartProducts.map((cartProduct: CartProduct) => {
        const pricesAndVat = calculateProductPrice(
            //@ts-ignore
            cartProduct.menuProduct || cartProduct.menuBundleProduct,
            cartProduct.orderProduct.quantity,
            cartProduct.orderProduct.modifications,
            cartProduct.orderProduct.selectedBundleProductItems,
            cartProduct.orderProduct.addons
        );

        return {
            ...cartProduct,
            orderProduct: {
                ...cartProduct.orderProduct,
                ...pricesAndVat
            }
        };
    });
};

/**
 * Function to calculate cart products that are still available in the to be added list
 * @param {CartProduct[]} updatedCartProducts
 * @param {SplitOrderWindowDiscounts | null} splitWindowDiscounts
 * @returns {CartProduct[]} cart products with discounts calculated amounts
 */
export const calculateDiscountsOnAvailable = (
    updatedCartProducts: CartProduct[],
    splitWindowDiscounts?: SplitOrderWindowDiscounts | null
) => {
    if (!splitWindowDiscounts) {
        return updateSplitProductPrices(updatedCartProducts);
    }

    const discounted = getDiscountsForCartProducts(updatedCartProducts, splitWindowDiscounts);

    if (!discounted) {
        return updateSplitProductPrices(updatedCartProducts);
    }
    const orderWindowForCalculation = {
        ...discounted,
        cartProducts: updatedCartProducts
    } as unknown as OrderWindow;
    return updateCombinedDiscountsAndOtherDiscounts(orderWindowForCalculation);
};

/**
 * Function to discount objects for selected cart products
 * @param {CartProduct[]} cartProducts
 * @param {SplitOrderWindowDiscounts | null} splitWindowDiscounts
 * @returns {SplitOrderWindowDiscounts | null}
 */
export const getDiscountsForCartProducts = (
    cartProducts: CartProduct[],
    splitWindowDiscounts?: SplitOrderWindowDiscounts | null
): SplitOrderWindowDiscounts | null => {
    if (!splitWindowDiscounts) {
        return null;
    }

    const cartProductIds = cartProducts.map((value: CartProduct) => value.id);
    const discountProductIds =
        splitWindowDiscounts?.discountedProductIds?.filter((value: string) => cartProductIds.includes(value)) ?? [];
    const discountQuantities =
        splitWindowDiscounts?.discountedIdsAndQuantity?.filter((value: CartIdAndQuantity) =>
            cartProductIds.includes(value.cartId)
        ) ?? [];
    if (discountProductIds.length) {
        return {
            discount: splitWindowDiscounts?.discount,
            discountedProductIds: discountProductIds,
            discountedIdsAndQuantity: discountQuantities
        };
    }
    return null;
};
