import React, { PropsWithChildren, useRef } from "react";

import { CartProduct, OrderWindow, SplitCartProductsState, SplitState } from "Types";
import { usePos } from "../pos";
import {
    calculateDiscountsOnAvailable,
    getDiscountsForCartProducts,
    updateSplitProductPrices
} from "./utils/splitCashRegisterHelpers";
import {
    onProductDecrease,
    onProductIncrease
} from "../../admin/components/online-orders/components/OnlineOrderRefundModal/refundProductsPickerUtils";
import { roundToTwo } from "NumberUtils";
import { POS_MODE, SPLIT_TYPE } from "Constants";
import { useMothershipMutation } from "Hooks";
import { UPSERT_ORDER_WINDOW, CHANGE_KITCHEN_ORDER_PAYMENT_STATUS } from "GraphQLMutations";
import { shouldUpsertOrderWindowToDb } from "Providers";
import { usePosStore, useOrderWindowsStore, useTableStore } from "Stores";

const initialSplitState = {
    mode: POS_MODE.REGULAR,
    fromOrderWindowId: null,
    to: []
};

export interface ISplitCashRegisterContext {
    posMode: SplitState["mode"];
    fromOrderWindowId: SplitState["fromOrderWindowId"];
    to: SplitState["to"];
    splitOrderIds: string[];
    isInSplitMode: boolean;
    splitCartProducts: SplitCartProductsState;
    splitOrderNo: number | null;
    splitMode: SPLIT_TYPE;
    isPaymentStarted: boolean;
    setSplit: (splitState: SplitState) => void;
    addSplitModeWindow: (orderWindow: OrderWindow) => void;
    addSplitOrderId: (orderId: string, orderNo: number) => void;
    addCartProductToPay: (cartProduct: CartProduct) => void;
    addCartProductToPayByAmount: (toPayCartProducts: CartProduct[], availableCartProducts: CartProduct[]) => void;
    addCartProductToPayByNumber: (amount: number) => void;
    isSplitModePaymentComplete: (paidOrderWindowId?: string) => boolean;
    addAllCartProductsToPay: () => void;
    removeAllCartProductsToPay: () => void;
    removeCartProductToPay: (cartProduct: CartProduct) => void;
    removeToPayCartProducts: (cartProducts: CartProduct[]) => void;
    setAvailableSplitCartProducts: (cartProduct: CartProduct[]) => void;
    clearCartProducts: () => void;
    leaveSplitMode: (isPaymentComplete: boolean) => void;
    handleSetSplitMode: (mode: SPLIT_TYPE) => void;
    handlePaymentStarted: (isStarted: boolean) => void;
    getSplitOrderWindowIds: () => string[];
}

export const SplitCashRegisterContext = React.createContext<ISplitCashRegisterContext | undefined>(undefined);

export const SplitCashRegisterProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { selectedPos } = usePosStore();
    const { checkResetCloseTable } = useTableStore();
    const {
        CartManager,
        splitOrder,
        setSplitOrder,
        splitWindowDiscounts,
        setSplitWindowDiscounts,
        splitMode,
        setSplitMode,
        isPaymentStarted,
        setIsPaymentStarted,
        splitCartProducts,
        setSplitCartProducts,
        split,
        setSplit
    } = useOrderWindowsStore();
    const { removeCartProducts } = CartManager;
    const [upsertOrderWindowMutation] = useMothershipMutation(UPSERT_ORDER_WINDOW);
    const [changeKOPaymentStatusMutation] = useMothershipMutation(CHANGE_KITCHEN_ORDER_PAYMENT_STATUS);

    const availableProductsRef = useRef<CartProduct[] | null>(null);

    const {
        setCartProductsToOrderWindow,
        addOrderWindow,
        upsertOrderWindows,
        handleSetActiveOrderWindowId,
        getOrderWindow,
        paymentTabsUtils: { clearOrderWindow }
    } = usePos();

    const addCartProductToPay = (cartProduct: CartProduct) => {
        const currCartProducts = splitCartProducts;
        const toPay = updateSplitProductPrices(
            onProductIncrease(currCartProducts.toPay, cartProduct, "orderProduct.quantity")
        );
        setCartProductsToOrderWindow(toPay, getDiscountsForCartProducts(toPay, splitWindowDiscounts));
        const updatedSplitCartProductState = {
            toPay,
            available: calculateDiscountsOnAvailable(
                onProductDecrease(currCartProducts.available, cartProduct, "orderProduct.quantity"),
                splitWindowDiscounts
            )
        };
        setSplitCartProducts(updatedSplitCartProductState);
    };

    const addCartProductToPayByAmount = (toPayCartProducts: CartProduct[], availableCartProducts: CartProduct[]) => {
        setSplitCartProducts({
            toPay: toPayCartProducts,
            available: availableCartProducts
        });
    };

    const handlePaymentStarted = (isStarted: boolean) => {
        setIsPaymentStarted(isStarted);
    };

    const addCartProductToPayByNumber = (numberOfReceipts: number) => {
        const updatedCartProducts = availableProductsRef.current!.map(cartProduct => {
            const updatedNetPrice = roundToTwo(cartProduct.orderProduct.totalNetPrice / numberOfReceipts);
            const updatedPrice = roundToTwo(cartProduct.orderProduct.totalPrice / numberOfReceipts);
            return {
                ...cartProduct,
                orderProduct: {
                    ...cartProduct.orderProduct,
                    totalNetPrice: +updatedNetPrice,
                    totalPrice: +updatedPrice
                }
            };
        });

        setSplitCartProducts({
            toPay: updatedCartProducts,
            available: []
        });
    };

    const addAllCartProductsToPay = () => {
        setCartProductsToOrderWindow(
            availableProductsRef.current!,
            getDiscountsForCartProducts(availableProductsRef.current!, splitWindowDiscounts)
        );
        setSplitCartProducts({
            toPay: availableProductsRef.current!,
            available: []
        });
    };

    const removeAllCartProductsToPay = () => {
        const currCartProducts = splitCartProducts;
        removeCartProducts(currCartProducts.toPay);
        setSplitCartProducts({
            toPay: [],
            available: calculateDiscountsOnAvailable(currCartProducts.toPay, splitWindowDiscounts)
        });
    };

    const removeCartProductToPay = (cartProduct: CartProduct) => {
        const currCartProducts = splitCartProducts;
        const toPay = updateSplitProductPrices(
            onProductDecrease(currCartProducts.toPay, cartProduct, "orderProduct.quantity")
        );

        setCartProductsToOrderWindow(toPay, getDiscountsForCartProducts(toPay, splitWindowDiscounts));
        const updatedSplitCartProducts = {
            toPay,
            available: calculateDiscountsOnAvailable(
                onProductIncrease(currCartProducts.available, cartProduct, "orderProduct.quantity"),
                splitWindowDiscounts
            )
        };
        setSplitCartProducts(updatedSplitCartProducts);
    };

    const removeToPayCartProducts = (cartProducts: CartProduct[]) => {
        const currCartProducts = splitCartProducts;
        availableProductsRef.current = currCartProducts.available;
        const updatedSplitCartProducts = {
            toPay: currCartProducts.toPay.filter(cartProductToPay =>
                cartProducts.every(cartProduct => cartProduct.id !== cartProductToPay.id)
            ),
            available: currCartProducts.available
        };
        setSplitCartProducts(updatedSplitCartProducts);
    };

    const setAvailableSplitCartProducts = (cartProducts: CartProduct[]) => {
        setSplitCartProducts({
            toPay: [],
            available: cartProducts
        });
    };

    const addSplitModeWindow = (orderWindow: OrderWindow) => {
        const newDestinationOrderWindow = addOrderWindow(
            orderWindow.id,
            orderWindow.takeAway || selectedPos?.preferTakeAway,
            orderWindow.tableId
        );

        const updatedSplitState = {
            mode: POS_MODE.SPLIT,
            fromOrderWindowId: orderWindow.id,
            to: split.to.concat(newDestinationOrderWindow.id)
        };
        if (orderWindow.discount) {
            setSplitWindowDiscounts({
                discount: orderWindow.discount,
                discountedProductIds: orderWindow.discountedProductIds,
                discountedIdsAndQuantity: orderWindow.discountedIdsAndQuantity
            });
        } else {
            setSplitWindowDiscounts(null);
        }

        setSplit(updatedSplitState);
        availableProductsRef.current = orderWindow?.cartProducts;
        setAvailableSplitCartProducts(orderWindow?.cartProducts);
    };

    const handleSetSplitMode = (mode: SPLIT_TYPE) => {
        setSplitMode(mode);
    };

    const addSplitOrderId = (orderId: string, orderNo: number) => {
        const currSplitOrder = splitOrder;
        const isFirstOrder = currSplitOrder.ids.length === 0;
        setSplitOrder({
            ids: currSplitOrder.ids.concat(orderId),
            orderNo: isFirstOrder ? orderNo : currSplitOrder.orderNo
        });
    };

    const isSplitModePaymentComplete = (paidOrderWindowId?: string) => {
        const unpaidOrderWindows = split.to.filter(orderWindowId => orderWindowId != paidOrderWindowId);
        const hasUnpaidOrderWindows = !!unpaidOrderWindows.length;

        return !splitCartProducts.available.length && !hasUnpaidOrderWindows;
    };

    const clearCartProducts = () => {
        availableProductsRef.current = [];
        setSplitCartProducts({ toPay: [], available: [] });
    };

    const leaveSplitMode = (isPaymentComplete: boolean) => {
        const fromOrderWindow = getOrderWindow(split.fromOrderWindowId);
        if (isPaymentComplete) {
            checkResetCloseTable();

            // Remove the original split source orderWindow
            clearOrderWindow(split.fromOrderWindowId);
            const updatedFromOrderWindow = {
                ...fromOrderWindow,
                cartProducts: availableProductsRef.current,
                previousCartProducts: availableProductsRef.current,
                deleted: true,
                shouldHide: true
            };
            if (shouldUpsertOrderWindowToDb(updatedFromOrderWindow)) {
                upsertOrderWindowMutation({
                    variables: { orderWindow: updatedFromOrderWindow }
                });
            }
            if (fromOrderWindow.postponePayment) {
                changeKOPaymentStatusMutation({ variables: { orderId: fromOrderWindow.postponeOrderId } });
            }
        } else {
            // Update and delete "from" orderWindow
            if (isPaymentStarted) {
                const updatedFromOrderWindow = {
                    ...fromOrderWindow,
                    cartProducts: availableProductsRef.current,
                    previousCartProducts: availableProductsRef.current
                };

                // Set all "to" orderWindows as hidden
                const toOrderWindows = split.to
                    .filter(orderWindowId => !!getOrderWindow(orderWindowId))
                    .map(orderWindowId => ({
                        ...getOrderWindow(orderWindowId),
                        shouldHide: true,
                        deleted: true
                    }));

                // Update the orderWindows state
                const updatedOrderWindows = [updatedFromOrderWindow, ...toOrderWindows];
                upsertOrderWindows(updatedOrderWindows);
                handleSetActiveOrderWindowId(fromOrderWindow.id);

                if (shouldUpsertOrderWindowToDb(updatedFromOrderWindow)) {
                    // Send the updated "from" window containing the actual products to the backend
                    upsertOrderWindowMutation({
                        variables: { orderWindow: updatedFromOrderWindow }
                    });
                }
            } else {
                // Update and hide "from" orderWindow
                const fromOrderWindow = getOrderWindow(split.fromOrderWindowId);

                // Set all "to" orderWindows as hidden
                const toOrderWindows = split.to
                    .filter(orderWindowId => !!getOrderWindow(orderWindowId))
                    .map(orderWindowId => ({
                        ...getOrderWindow(orderWindowId),
                        shouldHide: true,
                        deleted: true
                    }));

                // Update the orderWindows state
                const updatedOrderWindows = [fromOrderWindow, ...toOrderWindows];
                upsertOrderWindows(updatedOrderWindows);
                handleSetActiveOrderWindowId(fromOrderWindow.id);
            }
        }

        // Clear local split states
        setSplit(initialSplitState);
        setSplitOrder({ ids: [], orderNo: null });
        handleSetSplitMode(SPLIT_TYPE.DISHES);
        availableProductsRef.current = null;
        setIsPaymentStarted(false);
    };

    // TODO Make this better
    const getSplitOrderWindowIds = () => {
        return (split.to || []).concat(split.fromOrderWindowId || []);
    };

    const value = {
        posMode: split.mode,
        fromOrderWindowId: split.fromOrderWindowId,
        to: split.to,
        isInSplitMode: split.mode === POS_MODE.SPLIT,
        splitOrderIds: splitOrder.ids,
        splitOrderNo: splitOrder.orderNo,
        splitCartProducts,
        splitMode,
        isPaymentStarted,
        setSplit,
        addSplitModeWindow,
        addSplitOrderId,
        addCartProductToPay,
        addCartProductToPayByAmount,
        addCartProductToPayByNumber,
        isSplitModePaymentComplete,
        removeCartProductToPay,
        setAvailableSplitCartProducts,
        clearCartProducts,
        leaveSplitMode,
        removeToPayCartProducts,
        addAllCartProductsToPay,
        removeAllCartProductsToPay,
        handleSetSplitMode,
        handlePaymentStarted,
        getSplitOrderWindowIds
    };

    return <SplitCashRegisterContext.Provider value={value}>{children}</SplitCashRegisterContext.Provider>;
};

export const withSplitCashRegister = (Component: any) => (props: any) =>
    (
        <SplitCashRegisterContext.Consumer>
            {state => <Component {...props} splitCashState={state} />}
        </SplitCashRegisterContext.Consumer>
    );

export const useSplitCashRegister = () => {
    const ctx = React.useContext(SplitCashRegisterContext);
    if (!ctx) {
        throw new Error("useSplitCashRegister must be within SplitCashRegisterProvider");
    }
    return ctx;
};
