import { cloneDeep } from "lodash";
import hash from "object-hash";

import { calculateProductPrice } from "../../../admin/components/poses/pos/utils";
import {
    CartProduct,
    Addon,
    OrderWindow,
    OrderProduct,
    DISCOUNT_TYPE,
    CustomerMetaStatus,
    CustomerMeta,
    SelectedBundleProductItem
} from "Types";
import { deepOmitNullishValues, deepOmitTypename } from "Utils/objectHelpers";
import { stripFrontendOrderProductFields } from "Utils/productHelpers";

export const handleSetAddonsOnCartProduct = (
    cartProductToUpdate: CartProduct,
    selectedAddons: Addon[],
    bundleItemKey: string | null = null
) => {
    const { orderProduct } = cartProductToUpdate;
    let cartProductWithAddons;
    if (bundleItemKey) {
        const updatedBundleItems = orderProduct.selectedBundleProductItems!.map((bundleItem, idx) => {
            const compareBundleKey = bundleItem.name + idx;
            return bundleItemKey === compareBundleKey ? { ...bundleItem, addons: selectedAddons } : bundleItem;
        });
        cartProductWithAddons = {
            ...cartProductToUpdate,
            orderProduct: {
                ...cartProductToUpdate.orderProduct,
                selectedBundleProductItems: updatedBundleItems
            }
        };
    } else {
        cartProductWithAddons = {
            ...cartProductToUpdate,
            orderProduct: { ...orderProduct, addons: selectedAddons }
        };
    }

    return cartProductWithAddons;
};

/**
 * This should ignore discount and just detect if the quantity and contents of the
 * OrderProduct are identical
 */
const isSameOrderProduct = (thisOrderProduct: OrderProduct, thatOrderProduct: OrderProduct) => {
    const createComparableOrderProduct = (orderProduct: OrderProduct) => {
        const {
            totalComboUpsellValue,
            discountValue,
            discountIds,
            discountRate,
            combinedDiscounts,
            upsoldDiscountIds,
            comboDiscountValues,
            id,
            totalNetPrice,
            totalPrice,
            unitPrice,
            quantity,
            ...orderProductWithoutDiscounts
        } = orderProduct;

        return deepOmitNullishValues(deepOmitTypename(orderProductWithoutDiscounts));
    };
    const thisStripped = createComparableOrderProduct(thisOrderProduct);
    const thatStripped = createComparableOrderProduct(thatOrderProduct);
    return hash(thisStripped) == hash(thatStripped);
};

/**
 * This should ignore discount and just detect if the quantity and contents of the
 * OrderProduct are identical
 */
const isSameCartProduct = (thisOrderProduct: OrderProduct, thatOrderProduct: OrderProduct) => {
    const stripAllPriceInfo = (orderProduct: OrderProduct) => {
        const {
            totalComboUpsellValue,
            discountValue,
            discountIds,
            discountRate,
            combinedDiscounts,
            upsoldDiscountIds,
            comboDiscountValues,
            id,
            totalNetPrice,
            totalPrice,
            unitPrice,
            quantity,
            ...orderProductWithoutDiscounts
        } = orderProduct;

        return deepOmitTypename(orderProductWithoutDiscounts);
    };

    const thisStripped = stripAllPriceInfo(thisOrderProduct);
    const thatStripped = stripAllPriceInfo(thatOrderProduct);

    return hash(thisStripped) == hash(thatStripped);
};

export const getExtendedOrderProducts = (
    activeOrderWindow: OrderWindow,
    ignorePrice: boolean = true
): OrderProduct[] => {
    // Need to `cloneDeep` these arrays to not affect up the logic dependent on the orderProduct quantities outside of this function
    const previousCartProducts = cloneDeep(activeOrderWindow?.previousCartProducts);
    const currentCartProducts = cloneDeep(activeOrderWindow?.cartProducts);

    const existingCartProducts = [] as CartProduct[];
    const newCartProducts = [] as CartProduct[];

    /*
     * Scanning for identical products.
     *  When we find an identical product in the previous cart, we update the quantity in the order product.
     *  By updating the quantity, we keep track of which cart products we've matched with the previous cart products
     */
    currentCartProducts.forEach(cartProduct => {
        // Find an identical product in the previous cart
        const identicalCartProduct = previousCartProducts.find(previousCartProduct => {
            // Skip products that have already been used
            if (previousCartProduct.orderProduct.quantity <= 0) {
                return false;
            }

            // Check if the cart products match exactly
            const isMatchingCartProduct = previousCartProduct.id == cartProduct.id;
            if (isMatchingCartProduct) {
                // Determine the minimum quantity of the matching products
                const minimumQuantity = Math.min(
                    cartProduct.orderProduct.quantity,
                    previousCartProduct.orderProduct.quantity
                );

                // Update the quantities of the matching products
                previousCartProduct.orderProduct.quantity -= minimumQuantity;
                cartProduct.orderProduct.quantity -= minimumQuantity;
            }

            return isMatchingCartProduct;
        });

        // Add the matching product to the list of existing cart products
        if (identicalCartProduct) {
            existingCartProducts.push(cartProduct);
        } else {
            if (!ignorePrice) {
                newCartProducts.push(cartProduct);
            }
        }
    });
    /*
     * Scan for similar cart products, but this time, ignore discounts.  So a product with a discount
     *  is considered similar if everything except the discount information matches
     */
    if (ignorePrice) {
        currentCartProducts.forEach(cartProduct => {
            // Find an identical product in the previous cart
            const similarOrderProductExcludingDiscounts = previousCartProducts.find(previousCartProduct => {
                // Skip products that have already been used
                if (previousCartProduct.orderProduct.quantity <= 0 || cartProduct.orderProduct.quantity <= 0) {
                    return false;
                }

                // Check if the cart products match similarly
                const isMatchingCartProduct = isSameOrderProduct(
                    previousCartProduct.orderProduct,
                    cartProduct.orderProduct
                );
                if (isMatchingCartProduct) {
                    // Determine the minimum quantity of the matching products
                    const minimumQuantity = Math.min(
                        cartProduct.orderProduct.quantity,
                        previousCartProduct.orderProduct.quantity
                    );
                    // Update the quantities of the matching products
                    previousCartProduct.orderProduct.quantity -= minimumQuantity;
                    cartProduct.orderProduct.quantity -= minimumQuantity;
                }
                return isMatchingCartProduct;
            });
            if (similarOrderProductExcludingDiscounts) {
                // Add the matching product to the list of existing cart products
                existingCartProducts.push(cartProduct);
            } else {
                // This must be a new product
                newCartProducts.push(cartProduct);
            }
        });
    }

    /**
     * Get new order products with non-zero quantity
     */
    const onlyNewOrderProducts: OrderProduct[] = newCartProducts
        .filter(newCartProduct => newCartProduct.orderProduct.quantity > 0)
        .map(newCartProduct => newCartProduct.orderProduct);
    return onlyNewOrderProducts;
};

export const getExtendedCartProducts = (
    previousCartProducts: CartProduct[],
    currentCartProducts: CartProduct[],
    ignorePrice: boolean = true
): CartProduct[] => {
    const onlyNewOrderProducts: CartProduct[] = [];

    currentCartProducts.forEach(cartProduct => {
        // This product is in the old cart and the new cart
        const existingCartProduct = previousCartProducts.find(
            ignorePrice
                ? oldCartProduct => isSameCartProduct(cartProduct?.orderProduct, oldCartProduct?.orderProduct)
                : oldCartProduct => cartProduct?.orderProduct?.id === oldCartProduct?.orderProduct?.id
        );

        if (existingCartProduct) {
            let newQuantity = cartProduct.orderProduct.quantity - existingCartProduct.orderProduct.quantity;
            const hasAPotentialSplitProduct = cartProduct.id !== existingCartProduct.id && newQuantity !== 0;

            if (hasAPotentialSplitProduct) {
                const discountCartProduct = previousCartProducts.find(
                    (value: CartProduct) => value.id === cartProduct.id
                );
                if (discountCartProduct) {
                    newQuantity = cartProduct.orderProduct.quantity - discountCartProduct.orderProduct.quantity;
                }
            }

            // We don't want an orderProduct with 0 quantity
            if (newQuantity !== 0) {
                onlyNewOrderProducts.push({
                    ...cartProduct,
                    orderProduct: {
                        ...cartProduct.orderProduct,
                        quantity: newQuantity
                    }
                });
            }
        } else {
            // This product is new
            onlyNewOrderProducts.push(cartProduct);
        }
    });
    return onlyNewOrderProducts;
};

export const setExtendedOrderProducts = (orderWindow: OrderWindow) => {
    const updatedOrderWindow = {
        ...orderWindow,
        previousCartProducts: orderWindow?.cartProducts
    };

    return updatedOrderWindow;
};

export const findCartProductInCartProducts = (cartProducts: CartProduct[], cartProductId: string) => {
    return cartProducts.find(cartProduct => cartProduct.id === cartProductId);
};

export const mergeCartProductsById = (cartProducts: CartProduct[]) => {
    return cartProducts
        .reduce((cartProducts: Map<string, CartProduct>, cartProduct: CartProduct) => {
            if (cartProducts.has(cartProduct.id)) {
                const foundCartProduct = cartProducts.get(cartProduct.id) as CartProduct;

                const quantity = foundCartProduct?.orderProduct.quantity + cartProduct.orderProduct.quantity;
                const updatedPriceAndVats = calculateProductPrice(
                    foundCartProduct.menuProduct || cartProduct.menuBundleProduct,
                    quantity,
                    foundCartProduct.orderProduct.modifications,
                    foundCartProduct.orderProduct.selectedBundleProductItems,
                    foundCartProduct.orderProduct.addons,
                    foundCartProduct.updatedUnitPrice ? foundCartProduct.orderProduct.unitPrice : null
                );

                const updatedCartProduct = {
                    ...foundCartProduct,
                    orderProduct: {
                        ...foundCartProduct.orderProduct,
                        quantity,
                        ...updatedPriceAndVats
                    }
                } as CartProduct;
                cartProducts.set(cartProduct.id, updatedCartProduct);
            } else {
                cartProducts.set(cartProduct.id, cartProduct);
            }

            return cartProducts;
        }, new Map())
        .values();
};

export const transformAddedCartProductsToOrdered = (
    cartProducts: CartProduct[],
    orderedCartProducts: CartProduct[]
) => {
    const getCleanModifications = (modifications: any) => {
        const hasModifications = Object.values(modifications ?? {}).some(modValue => !!modValue);
        return hasModifications ? modifications : null;
    };
    const getCleanSelectedBundleItems = (
        selectedBundleProductItems: SelectedBundleProductItem[] | null | undefined
    ) => {
        if (!selectedBundleProductItems) {
            return null;
        }
        const hasSelectedBundleProductItems = !!selectedBundleProductItems?.length;

        if (!hasSelectedBundleProductItems) {
            return null;
        }

        return selectedBundleProductItems.map(item => {
            return {
                ...item,
                modifications: getCleanModifications(item.modifications)
            };
        });
    };

    return cartProducts.map(cartProduct => {
        const isOrdered = orderedCartProducts.find(orderedCartProduct => orderedCartProduct.id === cartProduct.id);

        if (!isOrdered) {
            return cartProduct;
        }

        // We only want to update ordered cartProducts
        const orderedCartProduct = {
            ...cartProduct,
            customerMeta: {
                ...cartProduct.customerMeta,
                status: CustomerMetaStatus.ORDERED
            } as CustomerMeta,
            orderProduct: stripFrontendOrderProductFields(cartProduct.orderProduct)
        } as CartProduct;

        const { orderProduct } = orderedCartProduct;

        const cleanModifications = getCleanModifications(orderProduct.modifications);
        const cleanSelectedBundleProductItems = getCleanSelectedBundleItems(
            orderProduct.selectedBundleProductItems ?? []
        );

        const hashValue = {
            id: orderedCartProduct.menuProduct?.id
                ? orderedCartProduct.menuProduct?.id
                : orderedCartProduct.menuBundleProduct?.id,
            selectedBundleProductItems: cleanSelectedBundleProductItems,
            modifications: cleanModifications,
            comment: orderedCartProduct.orderProduct.comment,
            addons: orderedCartProduct.orderProduct.addons,
            discount: null,
            customerMeta: orderedCartProduct.customerMeta
        };

        const id = hash(hashValue);

        return {
            ...orderedCartProduct,
            id
        };
    });
};

export const getCartProductsByCustomerMetaStatus = (cartProducts: CartProduct[], status: CustomerMetaStatus) => {
    return cartProducts.filter(cartProduct => cartProduct.customerMeta?.status === status);
};
export type CartProductDiscountInfo = {
    name: string;
    isSubscriptionDiscount: boolean;
};
export const getCartProductDiscountInfo = (
    cartProduct: CartProduct,
    orderWindow: OrderWindow
): CartProductDiscountInfo | null => {
    if (cartProduct.fixedDiscount?.isFixedDiscountActive) {
        const fixedDiscount = orderWindow?.fixedDiscounts?.find(
            discount => discount.id === cartProduct.fixedDiscount?.fixedDiscountId
        );

        if (fixedDiscount) {
            return {
                name: fixedDiscount.name,
                isSubscriptionDiscount: fixedDiscount.type === DISCOUNT_TYPE.SUBSCRIPTION_DISCOUNT
            };
        }
    } else if (orderWindow.discount?.name) {
        return {
            name: orderWindow.discount.name,
            isSubscriptionDiscount: false
        };
    }
    return null;
};

/**
 * Get the total quantity of all cartProducts
 * @param {CartProduct[]} cartProducts
 * @returns {number} Number of all cartProducts
 */

export const getCartProductsQuantity = (cartProducts: CartProduct[] | undefined) => {
    if (!cartProducts) {
        return 0;
    }

    return cartProducts.reduce((totalQuantity, { orderProduct }) => {
        return totalQuantity + orderProduct.quantity;
    }, 0);
};
