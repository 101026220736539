import { ApolloQueryResult } from "apollo-client";
import { ProductsStock, ProductsStockValue } from "Types";

interface PosInventoryStock {
    outOfStockAddonNames: string[];
    outOfStockProductIds: string[];
    inventoryStock: {
        refProductId: string;
        quantity: number;
    }[];
}

interface PosInventoryStockData {
    getShopOverride: PosInventoryStock;
}

interface PosInventoryStockVars {
    shopId: string;
}

type UsePosInventory = [
    (variables?: PosInventoryStockVars | undefined) => Promise<ApolloQueryResult<PosInventoryStockData>>
];

const outOfStockProductIdsToProductsStock = (outOfStockProductIds: PosInventoryStock["outOfStockProductIds"]) => {
    return outOfStockProductIds.map(id => [id, { remainingQuantity: 0, initialQuantity: 0 }]);
};

const inventoryStockToProductsStock = (inventoryStock: PosInventoryStock["inventoryStock"]) => {
    return inventoryStock.map(({ refProductId, quantity }) => [
        refProductId,
        { remainingQuantity: quantity, initialQuantity: quantity }
    ]);
};

export const getInitialStockState = ({ getShopOverride }: PosInventoryStockData): ProductsStock => {
    return new Map([
        ...outOfStockProductIdsToProductsStock(getShopOverride.outOfStockProductIds),
        ...inventoryStockToProductsStock(getShopOverride.inventoryStock)
    ] as any);
};

export const getNextStockQuantity = (
    cartProductQuantity: number,
    { initialQuantity, remainingQuantity }: ProductsStockValue
) => {
    const hasReachedStockLimit = initialQuantity <= cartProductQuantity || cartProductQuantity === initialQuantity;
    const hasStockDecreased = cartProductQuantity < initialQuantity;
    const shouldStopTrackingStock = isNaN(cartProductQuantity);

    if (hasReachedStockLimit) {
        return 0;
    } else if (shouldStopTrackingStock) {
        return initialQuantity;
    } else if (hasStockDecreased) {
        return initialQuantity - cartProductQuantity;
    } else {
        return remainingQuantity + cartProductQuantity;
    }
};

export const getOutOfStockProductIds = (
    productsStock: ProductsStock,
    quantityToCheck: "initialQuantity" | "remainingQuantity" = "initialQuantity"
) => {
    let ids = [];
    for (const [refId, stockStatus] of productsStock.entries()) {
        if (stockStatus[quantityToCheck] <= 0) {
            ids.push(refId);
        }
    }

    return ids;
};
