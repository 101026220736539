import React from "react";
import { isEqual, omit, groupBy } from "lodash";

import { Text, Flex, Box } from "Atoms";
import {
    RefProductCategory,
    MenuProductCategory,
    Menu
} from "Types";
import { modConstants } from "Constants";

// These types are prefixed with ProductLibrary to avoid naming conflicts with other types when importing from "Types"
// Exported in the ProductProvider context as ProductType, CategoryFromMenu, ShownProductType, ProductByCategories to avoid large refactor for now
import { ProductLibraryProductType, ProductLibraryCategoryFromMenu, ProductLibraryShownProductType, ProductLibraryProductByCategories, ProductLibraryCategoryAndProducts } from "Types";
export type ProductType = ProductLibraryProductType;
export type CategoryFromMenu = ProductLibraryCategoryFromMenu;
export type ShownProductType = ProductLibraryShownProductType;
export type ProductByCategories = ProductLibraryProductByCategories;
export type CategoryAndProducts = ProductLibraryCategoryAndProducts;

export const updateProductListOperations = {
    SAVE: "save",
    CONVERT: "convert",
    DELETE: "delete"
};

export const buildModificationChangedMessage = (menuCatProductNames: any, editProduct: ProductType) => {
    const flattenedMenus = menuCatProductNames.flatMap((menu: Menu) =>
        categoriesWithRefProductModifications(menu, editProduct!, null)
    );

    const groupedMenus = groupBy(flattenedMenus, "menuName");
    if (flattenedMenus.length > 0) {
        return (
            <Box>
                <Text textAlign={"left"}>
                    Produkten finns i följande menykategorier där den har andra modifikationer än de som uppdaterats.
                </Text>
                {Object.keys(groupedMenus).map((menu: string, idx: number) => {
                    const menuCategories = groupedMenus[menu];
                    return (
                        <Flex flexDirection={"column"} alignItems={"flex-start"} key={menu + idx} mb={2}>
                            <Text fontWeight={600} mb={0}>
                                Meny: {menu}
                            </Text>
                            {menuCategories.map((menuCategory: CategoryFromMenu, idx: number) => (
                                <Text pl={4} mb={0} key={menuCategory.catName + idx}>
                                    - {menuCategory.catName}
                                </Text>
                            ))}
                        </Flex>
                    );
                })}
                <Text textAlign={"left"} mt={4}>
                    Ni måste manuellt korrigera om ni vill ändra menyprodukternas modifkationspriser.
                </Text>
            </Box>
        );
    }
};

const excludeFiledFromArrayOfObjects = (list: any[], excludeField: string) => list.map(obj => omit(obj, excludeField));

const checkModificationChanged = (
    menuProductModifications: any,
    productModifications: any,
    modificationGroup: null | string
) => {
    if (modificationGroup === modConstants.MOD_FLAVOURS) {
        const filteredProductFlavours = excludeFiledFromArrayOfObjects(productModifications?.flavours, "__typename");
        const filteredMenuProductFlavours = excludeFiledFromArrayOfObjects(
            menuProductModifications?.flavours,
            "__typename"
        );
        return !isEqual(filteredProductFlavours, filteredMenuProductFlavours);
    } else if (modificationGroup === modConstants.MOD_SIZES) {
        const filteredProductSizes = excludeFiledFromArrayOfObjects(productModifications?.sizes, "__typename");
        const filteredMenuProductSizes = excludeFiledFromArrayOfObjects(menuProductModifications?.sizes, "__typename");
        return !isEqual(filteredProductSizes, filteredMenuProductSizes);
    } else return !isEqual(menuProductModifications, productModifications);
};

export const checkCategoryHasModifications = (
    category: MenuProductCategory,
    refProduct: ProductType,
    modificationGroup: null | string
) => {
    // check if any menuproduct is mapped to refProduct and if mods differ
    const menuProductWithChangedMods = category.menuProducts.some(menuProduct => {
        const isRefProductWithModifications = menuProduct.refProduct.id === refProduct.id && menuProduct.modifications;
        if (isRefProductWithModifications) {
            return checkModificationChanged(menuProduct.modifications!, refProduct.modifications!, modificationGroup);
        } else return isRefProductWithModifications;
    });

    // check if any bundleproduct includes this refProduct and if mods differ
    const menuBundleProductWithChangedMods = category.menuBundleProducts.some(menuProduct => {
        const refProductInBundle = !!menuProduct.menuBundleModifications
            ? menuProduct.menuBundleModifications.find(refProd => refProd.refProductId === refProduct.id)
            : null;
        return (
            refProductInBundle &&
            checkModificationChanged(refProductInBundle.modifications!, refProduct.modifications!, modificationGroup)
        );
    });
    return menuProductWithChangedMods || menuBundleProductWithChangedMods;
};

export const categoriesWithRefProductModifications = (
    menu: Menu,
    refProduct: ProductType,
    modificationGroup: null | string
) => {
    return menu.menuProductCategories
        .filter(menuCategoty => checkCategoryHasModifications(menuCategoty, refProduct, modificationGroup))
        .map(cat => {
            return {
                menuName: menu.name,
                catName: cat.name
            };
        });
};

export const buildProductByCategories = (categories: RefProductCategory[], products: ShownProductType[]) => {
    return categories.reduce((prev: ProductByCategories, currCat: RefProductCategory) => {
        const productsWithCurrentCategory = products.filter(
            (product: ShownProductType) => product.msProduct.refProductCategoryId === currCat.id
        );
        prev[currCat.id] = productsWithCurrentCategory || [];
        return prev;
    }, {});
};
