import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { isSuperAdminOrQoplaSupport, isUmbrellaAdmin, isUmbrellaUser } from "Utils";
import { useQopla } from "Providers/qoplaProvider/QoplaProvider";

export const useMismatch = (): void => {
    const navigate = useNavigate();
    const { logout, authenticatedUser, selectedShop } = useQopla();

    useEffect(() => {
        if (selectedShop?.id && authenticatedUser.companyId) {
            const isNotSuperAdminOrQoplaSupport = !isSuperAdminOrQoplaSupport(authenticatedUser.roles);
            const hasMissMatchedCompanyIds = selectedShop?.companyId !== authenticatedUser.companyId;
            const hasUmbrellaUserRole = isUmbrellaUser(authenticatedUser.roles);
            const hasUmbrellaAdminRole = isUmbrellaAdmin(authenticatedUser.roles);

            if (
                hasMissMatchedCompanyIds &&
                isNotSuperAdminOrQoplaSupport &&
                !hasUmbrellaUserRole &&
                !hasUmbrellaAdminRole
            ) {
                console.log("Logging out due to mismatch companyId");
                logout();
                navigate("/admin", {
                    state: {
                        from: location.pathname,
                        redirectReasons: {
                            error: "Invalid companyId"
                        }
                    }
                });
            }
        }
    }, [selectedShop, authenticatedUser]);
};
