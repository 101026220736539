import React from "react";
import NewWindow from "react-new-window";

import { QOPLA_PERSONAL_KOLLEN_URL } from "Constants";

type Props = {
    newWindowUnloaded: () => void;
};

export const PunchClockPage: React.FC<Props> = ({ newWindowUnloaded }) => (
    <NewWindow
        url={QOPLA_PERSONAL_KOLLEN_URL}
        center="parent"
        onUnload={newWindowUnloaded}
        features={{ left: "50%", top: "50%", width: 600, height: 600 }}
    />
);
