import { v4 } from "uuid";

import {
    CartProduct,
    CustomerMetaStatus,
    Modifications,
    OrderWindow,
    OrderWindowCustomer,
    OrderWindowCustomerStatus,
    SelectedBundleProductItem,
    StringOrNull
} from "Types";
import { mergeCartProductsById } from "./cartProductHelpers";
import { getCleanGroupOrderNickname } from "../../../customer/shared/groupOrder/utils";

export const getOrderWindowById = (orderWindows: OrderWindow[], orderWindowId: string) =>
    orderWindows.find(orderWindow => orderWindow.id == orderWindowId);

export const getOrderWindowsForTable = (orderWindows: OrderWindow[], tableId: string | null) =>
    orderWindows.filter(orderWindow => orderWindow.tableId == tableId);

export const getAllTableOrderWindows = (orderWindows: OrderWindow[]) =>
    orderWindows.filter(orderWindow => !!orderWindow.tableId);

export const getAllFromSplitModeOrderWindows = (orderWindows: OrderWindow[]) =>
    orderWindows.filter(orderWindow => !!orderWindow.fromSplitOrderWindowId);

export const getLocalOrderWindows = (orderWindows: OrderWindow[]) =>
    orderWindows.filter(
        orderWindow =>
            !orderWindow.tableId &&
            !orderWindow.postponeOrderId &&
            !orderWindow.deleted &&
            !orderWindow.shouldHide &&
            !orderWindow.isFromSocket // This is the special case for group ordering
    );

const isTableOrderWindow = (orderWindow: OrderWindow) => !!orderWindow.tableId;

const isParkedOrderWindow = (orderWindow: OrderWindow) =>
    orderWindow.postponePayment && !orderWindow.tableId && orderWindow?.contactInformation?.name;

export const shouldUpsertOrderWindowToDb = (orderWindow: OrderWindow) =>
    isParkedOrderWindow(orderWindow) || isTableOrderWindow(orderWindow);

// Table orderWindows are also "postponed", but we want to treat these as PURE postponed orderWindows
export const getPostponedOrderWindows = (orderWindows: OrderWindow[]) =>
    orderWindows.filter(orderWindow => orderWindow.postponePayment && !orderWindow.deleted && !orderWindow.tableId);

export const generateOrderWindowName = (orderWindows: OrderWindow[], prefix = "") => {
    const regexReplaceNonNumeric = new RegExp(/[^\d]/g);
    const maxDisplayNameIndex = orderWindows.reduce((acc, orderWindow) => {
        // Remove all alpha characters from the string
        if (!String(orderWindow.displayName).includes(prefix)) {
            return acc;
        }
        const num = String(orderWindow.displayName).replace(regexReplaceNonNumeric, "");
        // Use the number in the displayname to find the next available orderWindow displayname
        acc = Math.max(acc, +num);
        return acc;
    }, 0);

    return `${prefix} ${maxDisplayNameIndex + 1}`;
};

export const createNewOrderWindow = (
    shopId: string,
    orderWindows: OrderWindow[],
    takeAway: boolean,
    activeWindowFromSplitMode: OrderWindow | null | undefined,
    tableId?: string | undefined | null
): OrderWindow => {
    const id = v4();

    let nextDisplayName = generateOrderWindowName(orderWindows, "Nota");

    let hasDiscountsToPass = false;
    if (activeWindowFromSplitMode) {
        const orderWindowHasDiscount = activeWindowFromSplitMode?.discount;
        hasDiscountsToPass = activeWindowFromSplitMode && orderWindowHasDiscount;
        // In the future, maybe `displayName` should actually be set to
        //  contactInformation.name
        const sourceOrderWindowDisplayName =
            activeWindowFromSplitMode.contactInformation?.name || activeWindowFromSplitMode.displayName;
        nextDisplayName = `Från ${sourceOrderWindowDisplayName}`;
    }

    return {
        id,
        displayName: nextDisplayName,
        postponePayment: false,
        cartProducts: [],
        discount: hasDiscountsToPass ? activeWindowFromSplitMode?.discount : null,
        takeAway: takeAway,
        previousCartProducts: [],
        contactInformation: null,
        postponeOrderId: null,
        puckNo: null,
        discountedProductIds: hasDiscountsToPass ? activeWindowFromSplitMode?.discountedProductIds || [] : [],
        tableId,
        shopId,
        shouldHide: false,
        deleted: false,
        fromSplitOrderWindowId: activeWindowFromSplitMode?.id || null,
        customers: [],
        isFromSocket: false,
        tip: 0,
        groupOrderNicknames: [],
        groupOrderStarted: false
    };
};

export const getVisibleOrderWindows = (
    orderWindows: OrderWindow[],
    selectedTableId?: string | null,
    splitOrderWindowIds: string[] = []
) => {
    const tableOrderWindows: OrderWindow[] = [];
    const splitOrderWindows: OrderWindow[] = [];
    const normalOrderWindows: OrderWindow[] = [];

    const conditionalPush = (orderWindow: OrderWindow, arr: OrderWindow[]) =>
        !orderWindow.shouldHide && !orderWindow.deleted && arr.push(orderWindow);

    orderWindows.forEach(orderWindow => {
        /** Only orderWindows for the selected table should be visible */
        if (orderWindow.tableId) {
            if (orderWindow.tableId == selectedTableId) {
                conditionalPush(orderWindow, tableOrderWindows);
            }
        } else if (splitOrderWindowIds.includes(orderWindow.id)) {
            conditionalPush(orderWindow, splitOrderWindows);
        } else {
            conditionalPush(orderWindow, normalOrderWindows);
        }
    });

    if (selectedTableId) {
        return tableOrderWindows;
    }

    if (splitOrderWindows?.length) {
        return splitOrderWindows;
    }

    return normalOrderWindows;
};

export const mergeOrderWindows = (existingOrderWindows: OrderWindow[], orderWindowsToAdd: OrderWindow[]) => {
    const allOrderWindows = [...existingOrderWindows, ...orderWindowsToAdd];
    return allOrderWindows.reduce<OrderWindow[]>((acc, next) => {
        // This is to prevent duplicates in the results
        const alreadyPushed = acc.find(accOrderWindow => accOrderWindow.id === next.id);
        if (alreadyPushed) {
            return acc;
        }

        // Check if this is an existing orderWindow
        const foundExisting = existingOrderWindows.find(existingOrderWindow => existingOrderWindow.id === next.id);
        // Check if this orderWindow is added or modified
        const foundAdd = orderWindowsToAdd.find(orderWindowToAdd => orderWindowToAdd.id === next.id);

        // This means the orderWindow exists in both arrays
        const hasBeenUpdated = foundExisting?.id === foundAdd?.id;

        if (hasBeenUpdated && foundAdd) {
            // Modified
            acc.push(foundAdd);
            return acc;
        }

        // Unmodified
        acc.push(next);

        return acc;
    }, []);
};

export const getNewOrderWindowId = (indexToRemove: number, orderWindows: OrderWindow[]): string | null => {
    const previousOrderWindowIndex = indexToRemove;
    const newActiveOrderWindowIndex = Math.min(orderWindows.length - 1, Math.max(0, previousOrderWindowIndex));

    const newOrderWindowId = orderWindows[newActiveOrderWindowIndex]?.id || null;
    return newOrderWindowId;
};

/**
 * This is a huge workaround because we store modifications as arrays in the backend, like this:
 *  modifications: {
 *    sizes: [ { name: "", price: xx } ]
 *  }
 *
 * But locally, we expect an object like this:
 * modifications : {
 *    sizes: { name: "", price: xx }
 * }
 *
 * This is a more deeply rooted problem, but in order for the frontend to be able to display the
 * order products, this transformation is necessary
 *
 * NOTE: This should NOT be used anywhere other than the useEffect below when the orderWindows are fetched.  Keep this close to the usage please.
 *
 * @param orderWindows
 * @returns
 */
// FML
export const transformNestedOrderProductModifications = (orderWindows: OrderWindow[]): OrderWindow[] => {
    const getFirstOrNull = (item: any) => {
        /**
         * Convert arrays to the first object
         */
        if (item?.length > 0) {
            return item[0];
        }

        /**
         * Use a null value for empty arrays
         */
        if (item?.length == 0) {
            return null;
        }

        /**
         * If already an object, return that object.
         * This one is nasty, because if for some reason accidentally save array/object mixtures in modifications, then
         *  this is the catch code for that case.  Arguably, that should never happen in the first place.
         */
        return item;
    };

    const transformModifications = (modifications?: Modifications) => {
        const transformedModifications = modifications;
        if (!modifications || !transformedModifications) {
            return modifications;
        }

        const sizes = getFirstOrNull(modifications?.sizes);
        const flavours = getFirstOrNull(modifications?.flavours);
        transformedModifications.sizes = sizes;
        transformedModifications.flavours = flavours;

        return transformedModifications;
    };

    const transformBundleProductItems = (
        selectedBundleProductItems: SelectedBundleProductItem[] | null | undefined
    ): SelectedBundleProductItem[] | null => {
        if (!selectedBundleProductItems) {
            return null;
        }

        return selectedBundleProductItems.map(bundleItem => {
            return {
                ...bundleItem,
                modifications: transformModifications(bundleItem?.modifications as Modifications)
            };
        });
    };

    return orderWindows.map(orderWindow => {
        return {
            ...orderWindow,
            cartProducts: orderWindow.cartProducts?.map(cartProduct => {
                return {
                    ...cartProduct,
                    orderProduct: {
                        ...cartProduct.orderProduct,
                        modifications: transformModifications(cartProduct.orderProduct.modifications),
                        selectedBundleProductItems: transformBundleProductItems(
                            cartProduct?.orderProduct?.selectedBundleProductItems
                        )
                    }
                };
            }),
            previousCartProducts:
                orderWindow.previousCartProducts?.map(cartProduct => {
                    return {
                        ...cartProduct,
                        orderProduct: {
                            ...cartProduct.orderProduct,
                            modifications: transformModifications(cartProduct.orderProduct.modifications),
                            selectedBundleProductItems: transformBundleProductItems(
                                cartProduct?.orderProduct?.selectedBundleProductItems
                            )
                        }
                    };
                }) ?? []
        };
    });
};

// For now only one condition: parked orders shouldn't appear on DT kassor
export const getOrderWindowsForPos = (isDriveThroughPOS: boolean, orderWindows: OrderWindow[]) => {
    return orderWindows.filter(orderWindow => (isDriveThroughPOS ? !orderWindow.contactInformation : orderWindow));
};

export const findOrderWindowCustomerByNickname = (
    orderWindowCustomers: FullOrderWindowCustomer[] | OrderWindowCustomer[],
    nickname: StringOrNull
): FullOrderWindowCustomer | OrderWindowCustomer | undefined => {
    return orderWindowCustomers.find(orderWindowCustomer => orderWindowCustomer?.nickname === nickname);
};

/*
[
    {
        nickname: string,
        status: string,
        cartProducts: [],
        previousCartProducts:[]
    }
]
*/

export const customerToFullOrderWindowCustomers = (
    orderWindow: OrderWindow,
    includedCustomers: OrderWindowCustomer[]
) => {
    const fullOrderWindowCustomers = getFullOrderWindowCustomersFromOrderWindow(orderWindow);

    return fullOrderWindowCustomers.filter(fullOrderWindowCustomer =>
        includedCustomers.some(includedCustomer => includedCustomer.nickname === fullOrderWindowCustomer.nickname)
    );
};

// TODO - Rename this type
export type FullOrderWindowCustomer = {
    cleanNickname: string;
    cartProducts: CartProduct[];
    previousCartProducts: CartProduct[];
} & OrderWindowCustomer;
export const getFullOrderWindowCustomersFromOrderWindow = (orderWindow: OrderWindow) => {
    const joinedCustomers = (orderWindow?.customers || []).filter(
        customer => customer.status != OrderWindowCustomerStatus.REQUEST_TO_JOIN
    );
    return (
        joinedCustomers?.reduce<FullOrderWindowCustomer[]>((customers, customer) => {
            const cartProducts = orderWindow?.cartProducts?.filter(
                cartProduct => cartProduct.customerMeta?.addedBy === customer.nickname
            );

            const mergedCartProducts = Array.from(mergeCartProductsById(cartProducts));

            const previousCartProducts = orderWindow?.previousCartProducts?.filter(
                previousCartProduct => previousCartProduct.customerMeta?.addedBy === customer.nickname
            );

            customers.push({
                ...customer,
                cleanNickname: getCleanGroupOrderNickname(customer.nickname),
                cartProducts: mergedCartProducts,
                previousCartProducts
            });

            return customers;
        }, []) ?? []
    );
};

export const isInGroupOrder = (orderWindow: OrderWindow | undefined, nickname: string) =>
    orderWindow?.groupOrderNicknames?.includes(nickname) ?? false;
