import React, { PropsWithChildren } from "react";
import { useMutation } from "react-apollo";

import { CartProduct } from "Types";
import { useQopla, usePos, useTheme } from "Providers";
import { convertCartProductsToOrderProducts } from "../../utils/productHelpers";
import { CREATE_WASTE_PRODUCT } from "GraphQLMutations";
import { WasteProductDTOInput } from "../../types/wasteTypes";
import { confirmOrCancelNotification } from "TempUtils";
import { useOrderWindowsStore } from "Stores";

export interface IWasteProductContext {
    registerWaste: (orderWindowId: string, cartProducts: CartProduct[]) => void;
}

export const WasteProductContext = React.createContext<IWasteProductContext | undefined>(undefined);

const buildWasteProductInput = (shopId: string, cartProducts: CartProduct[]): WasteProductDTOInput => {
    const orderProducts = convertCartProductsToOrderProducts(cartProducts);

    return {
        shopId,
        orderProducts
    };
};

export const WasteProductProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { colors } = useTheme();
    const { emptyCart } = useOrderWindowsStore();
    const { selectedShop } = useQopla();
    const shopId = selectedShop?.id;

    const [createWasteProduct] = useMutation<WasteProductDTOInput>(CREATE_WASTE_PRODUCT);

    const registerWaste = async (orderWindowId: string, cartProducts: CartProduct[]): Promise<void> => {
        const { value } = await confirmOrCancelNotification("Svinna dessa produkter?", "", "Ja", colors);
        if (value && !!shopId) {
            const wasteProductInput = buildWasteProductInput(shopId, cartProducts);
            try {
                // We decided to not await here.
                // The alternative would be await + spinner to avoid a stale UI state where it's impossible to understand the status of the app.
                createWasteProduct({
                    variables: {
                        wasteProductInput
                    }
                });

                emptyCart(orderWindowId);
            } catch (error) {
                console.error("ERROR", error);
            }
        }
    };

    const value = {
        registerWaste
    };

    return <WasteProductContext.Provider value={value}>{children}</WasteProductContext.Provider>;
};

export const useWasteProduct = () => {
    const ctx = React.useContext(WasteProductContext);
    if (!ctx) {
        throw new Error("useWasteProduct must be within WasteProductProvider");
    }
    return ctx;
};
