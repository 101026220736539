import React, { createContext, PropsWithChildren, useEffect } from "react";
import moment, { Moment } from "moment";
import { flatMap } from "lodash";
import { useLocation } from "react-router-dom";

import { LoadingMessage } from "TempUtils";
import { useImperativeQuery } from "Hooks";
import { formatQueryDataResponse } from "Utils";
import {
    GET_COMPANY_SUBSCRIPTION_SETTINGS,
    GET_SUBSCRIPTION_BY_SHOP_ID,
    GET_USER_ACCOUNT_SUBSCRIPTIONS_BY_SHOP_ID
} from "GraphQLQueries";
import { Subscriptions, SubscriptionStatus, UserSubscriptions } from "Types";
import { useAuthUser } from "../authUserProvider";
import { transformSubscriptionsToFixedDiscounts } from "./utils";
import { newMothershipApolloClient } from "../../graphql/clients";
import { useOnlineStore, useOrderWindowsStore, useQoplaStore } from "Stores";
import { useOnline } from "../onlineProvider";

type SubscriptionsOnHold = { [id: string]: Moment };

export interface ISubscriptionsContext {
    hasPickedSubscriptionProduct: boolean;
    subscriptions: Subscriptions;
    isPromotionForShop: boolean;
    hasAddedSubscriptionProduct: boolean;
    userSubscriptions: UserSubscriptions;
    subscriptionsOnHold: SubscriptionsOnHold;
    removeSubscriptionFromHold: (id: string) => void;
}

export const SubscriptionsContext = createContext<ISubscriptionsContext | undefined>(undefined);

export type SubscriptionState = {
    subscriptions: Subscriptions;
    userSubscriptions: UserSubscriptions;
    isLoading: boolean;
    isPromotionForShop: boolean;
    subscriptionsOnHold: SubscriptionsOnHold;
    hasAddedSubscriptionProduct: boolean;
};

const defaultState: SubscriptionState = {
    subscriptions: [],
    userSubscriptions: [],
    isLoading: true,
    isPromotionForShop: true,
    subscriptionsOnHold: {},
    hasAddedSubscriptionProduct: false
};

export const SubscriptionsProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { setAllActiveFixedDiscounts: onSetAllActiveFixedDiscounts } = useOrderWindowsStore();
    const { subscriptionState: state, setSubscriptionState: setState } = useOnlineStore();
    const { backendDiff } = useOnline();

    const { userAccount } = useAuthUser();
    const { selectedShop } = useQoplaStore();

    const { pathname } = useLocation();

    // Query to run when having user account
    const loadUserAccountSubscriptions = useImperativeQuery(GET_USER_ACCOUNT_SUBSCRIPTIONS_BY_SHOP_ID, {
        client: newMothershipApolloClient,
        fetchPolicy: "network-only"
    });

    // Query to run when no user account
    // Will use to promote shops subscription products
    const loadSubscriptionQuery = useImperativeQuery(GET_SUBSCRIPTION_BY_SHOP_ID, {
        client: newMothershipApolloClient,
        fetchPolicy: "network-only"
    });

    const loadCompanySubscriptionSettings = useImperativeQuery(GET_COMPANY_SUBSCRIPTION_SETTINGS);

    useEffect(() => {
        const loadSubscriptions = async () => {
            if (userAccount && selectedShop && selectedShop.id) {
                const res = await Promise.all([
                    loadUserAccountSubscriptions({
                        userAccountId: userAccount.id,
                        shopId: selectedShop.id
                    }),
                    loadSubscriptionQuery({
                        shopId: selectedShop.id
                    })
                ]);

                const userAccountAndShopSubscriptions = formatQueryDataResponse(flatMap(res, query => query.data));

                const hasLoaded =
                    userAccountAndShopSubscriptions &&
                    userAccountAndShopSubscriptions.getUserSubscriptionsByShopId &&
                    userAccountAndShopSubscriptions.getAllSubscriptionsByShopId;

                if (hasLoaded) {
                    const { getUserSubscriptionsByShopId, getAllSubscriptionsByShopId } =
                        userAccountAndShopSubscriptions;

                    // Get ONLY active subscriptions
                    const userSubscriptions: UserSubscriptions = getUserSubscriptionsByShopId?.filter(
                        (userSubscription: any) => userSubscription.status === SubscriptionStatus.ACTIVE
                    );

                    const shopsSubscriptions: Subscriptions = getAllSubscriptionsByShopId;

                    // Get shop subscriptions or active subscription information
                    const subscriptions: Subscriptions = !!userSubscriptions.length
                        ? userSubscriptions.map(userSubscription => userSubscription.subscription)
                        : shopsSubscriptions;

                    // Not to set allActiveFixedDiscount on the checkout
                    // Has to use fixeded discounts from orderWindow
                    const isOnCheckoutRoute = pathname.includes("checkout");
                    if (!isOnCheckoutRoute) {
                        onSetAllActiveFixedDiscounts(transformSubscriptionsToFixedDiscounts(subscriptions));
                    }

                    const { data: companySubscriptionSettingsData } = await loadCompanySubscriptionSettings({
                        companyId: selectedShop.companyId
                    });

                    let subscriptionsOnHold: SubscriptionsOnHold = {};
                    if (companySubscriptionSettingsData.getCompanySubscriptionSettingsById) {
                        const { minimumPurchaseInterval } =
                            companySubscriptionSettingsData.getCompanySubscriptionSettingsById;

                        subscriptionsOnHold = userSubscriptions.reduce<SubscriptionsOnHold>((acc, next) => {
                            const reEnabledMomentUTC = moment
                                .utc(next.latestOrderTimestamp)
                                .add(minimumPurchaseInterval, "ms")
                                .add(backendDiff, "ms");
                            const nowMomentUTC = moment.utc();

                            /**
                             * We haven't reached the re-enabled time to enable the subscription
                             */
                            const shouldPauseIt = nowMomentUTC.isBefore(reEnabledMomentUTC);

                            if (shouldPauseIt) {
                                acc[next.subscription.id] = reEnabledMomentUTC;
                            }
                            return acc;
                        }, {});
                    }

                    setState({
                        subscriptions,
                        isLoading: false,
                        isPromotionForShop: false,
                        userSubscriptions,
                        subscriptionsOnHold,
                        hasAddedSubscriptionProduct: false
                    });
                }
            } else if (selectedShop?.id) {
                const { data } = await loadSubscriptionQuery({
                    shopId: selectedShop?.id
                });

                if (data && data.getAllSubscriptionsByShopId) {
                    const subscriptions: Subscriptions = data.getAllSubscriptionsByShopId.map(
                        (shopSubscription: any) => shopSubscription
                    );

                    onSetAllActiveFixedDiscounts(transformSubscriptionsToFixedDiscounts(subscriptions));

                    setState({
                        subscriptions,
                        isLoading: false,
                        isPromotionForShop: true,
                        userSubscriptions: [],
                        subscriptionsOnHold: {},
                        hasAddedSubscriptionProduct: false
                    });
                }
            } else {
                setState({
                    subscriptions: [],
                    isLoading: false,
                    isPromotionForShop: true,
                    userSubscriptions: [],
                    subscriptionsOnHold: {},
                    hasAddedSubscriptionProduct: false
                });
            }
        };

        loadSubscriptions();
    }, [userAccount, selectedShop]);

    const removeSubscriptionFromHold = (id: string) => {
        const { [id]: value, ...subscriptionsOnHold } = state.subscriptionsOnHold;
        const newState = {
            ...state,
            subscriptionsOnHold
        };
        setState(newState);
    };

    const value = {
        subscriptions: state.subscriptions,
        hasPickedSubscriptionProduct: false,
        isPromotionForShop: state.isPromotionForShop,
        userSubscriptions: state.userSubscriptions,
        hasAddedSubscriptionProduct: state.hasAddedSubscriptionProduct,
        subscriptionsOnHold: state.subscriptionsOnHold,
        removeSubscriptionFromHold
    };

    if (state.isLoading) {
        return <LoadingMessage />;
    }

    return <SubscriptionsContext.Provider value={value}>{children}</SubscriptionsContext.Provider>;
};

export const useSubscriptions = () => {
    const ctx = React.useContext(SubscriptionsContext);
    if (!ctx) {
        throw new Error("useSubscriptions must be within SubscriptionsProvider");
    }
    return ctx;
};
