import { PaymentTab } from "Types";

export const findActivePaymentTabsByStatuses = (statuses: string[], activePaymentTabs: Record<string, PaymentTab>) => {
    const paymentTabArray = Object.values(activePaymentTabs);

    return paymentTabArray.filter(paymentTab => statuses.includes(paymentTab.status as string));
};

export const findActivePaymentTabsByStatus = (status: string, activePaymentTabs: Record<string, PaymentTab>) => {
    return findActivePaymentTabsByStatuses([status], activePaymentTabs);
};
