import React, { PropsWithChildren, useEffect, useState } from "react";

import { infoNotification } from "TempUtils";
import {
    GET_PRODUCTS_IN_MENUS,
    GET_PRODUCTS_BY_COMPANY_ID,
    GET_PRODUCT_CATEGORIES,
    GET_COMPANY_PRODUCT_SIE_ACCOUNTS,
    GET_REDUCED_MENUS_MODIFICATIONS
} from "GraphQLQueries";
import { useQopla } from "Providers";
import { useLanguage } from "LanguageProvider";
import {
    UPSERT_REF_PRODUCT_CATEGORY,
    SAVE_PRODUCTS,
    SAVE_BUNDLE_PRODUCTS,
    UPDATE_REF_CATEGORIES_SORT_ORDER,
    DELETE_PRODUCT_CATEGORY
} from "GraphQLMutations";
import { useMothershipMutation, useMothershipLazyQuery } from "Hooks";
import { useQuery } from "react-apollo";
import { RefProductCategory, PriceType, CountryLocaleId, Menu, RefProduct, RefBundleProduct } from "Types";
import { confirmNotification } from "TempUtils";
import {
    ProductType,
    ShownProductType,
    ProductByCategories,
    CategoryAndProducts,
    buildModificationChangedMessage,
    buildProductByCategories,
    updateProductListOperations,
    categoriesWithRefProductModifications
} from "./utils";
import { showToastSuccess, showToastWarning, showToastError } from "Utils";
import { setSessionStorage, DIRTY_PRODUCTS, getSessionStorage } from "../../admin/components/product/utils";

export interface IProductLibraryContext {
    productsByCategories: ProductByCategories;
    productCategories: RefProductCategory[];
    dirtyCategories: RefProductCategory[];
    collapsedCategories: Set<string>;
    savingLoader: boolean;
    isNewProductEditorOpen: boolean;
    isDragAndDropProductPrevented: boolean;
    bundleCategoriesData: CategoryAndProducts;
    missingSIEAccountsByCategories: string[];
    isSwedishCompanyAndSellsAlcohol: boolean;
    shouldShowPrompt: boolean;
    setIsNewProductEditorOpen: (open: boolean) => void;
    saveProduct: (
        products: ProductType[],
        modificationChanged: boolean,
        oldCategoryId?: string
    ) => Promise<(RefProduct | RefBundleProduct)[] | null>;
    saveCategoryName: (categories: RefProductCategory[]) => void;
    updateCategory: (category: RefProductCategory) => Promise<RefProductCategory | null>;
    updateCategories: (categories: RefProductCategory[]) => Promise<RefProductCategory[] | null>;
    updateProductByCategories: (updatedProductByCategories: ProductByCategories) => void;
    setCollapsedCategories: (categoryName: Set<string>) => void;
    deleteProduct: (product: ProductType) => void;
    convertProduct: (product: any, idx: number) => void;
    toggleCollapseAllCategories: () => void;
    saveCategorySortOrder: (
        newCategories: RefProductCategory[],
        deletedcategoryName: string,
        isAddingNewCategory: boolean,
        needToUpdateAllCategories?: boolean
    ) => void;
    deleteRefCategory: (refProductCategoryId: string, categoryIdx: number) => void;
    setIsDragAndDropProductPrevented: (prevented: boolean) => void;
    setShouldShowPrompt: (shouldShowPrompt: boolean) => void;
    setDirtyCategoriesAndPrompt: (category: RefProductCategory[]) => void;
    handleToggleShouldShowPrompt: (isDirtyProducts: boolean | null, isDirtyCategories: boolean | null) => void;
    checkOverridenModificationsGroup: (
        product: ProductType,
        modificationsGroup: null | string
    ) => { menuName: string; catName: string }[];
}

export const ProductLibraryContext = React.createContext<IProductLibraryContext | undefined>(undefined);

export const ProductLibraryProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { selectedCompany } = useQopla();
    const { translate, translateWithArgument } = useLanguage();

    const [productsByCategories, setProductsByCategories] = useState<ProductByCategories | {}>({});
    const [productCategories, setProductCategories] = useState<RefProductCategory[]>([]);
    const [bundleCategoriesData, setBundleCategoriesData] = useState<CategoryAndProducts>([]);
    const [savingLoader, setSavingLoader] = useState<boolean>(true);
    const [isSwedishCompanyAndSellsAlcohol, setIsSwedishCompanyAndSellsAlcohol] = useState<boolean>(false);

    const [dirtyCategories, setDirtyCategories] = useState<RefProductCategory[]>([]);
    const [isNewProductEditorOpen, setIsNewProductEditorOpen] = useState<boolean>(false);
    const [productIdsInMenu, setProductIdsInMenu] = useState<Set<string>>(new Set());

    const [collapsedCategories, setCollapsedCategories] = useState<Set<string>>(new Set());
    const [isDragAndDropProductPrevented, setIsDragAndDropProductPrevented] = useState<boolean>(true);
    const [missingSIEAccountsByCategories, setMissingSIEAccountsByCategories] = useState<string[]>([]);

    const [shouldShowPrompt, setShouldShowPrompt] = useState<boolean>(false);

    const [saveProductsMutation] = useMothershipMutation(SAVE_PRODUCTS);
    const [saveBundleProductsMutation] = useMothershipMutation(SAVE_BUNDLE_PRODUCTS);
    const [updateRefCategoriesSortOrder] = useMothershipMutation(UPDATE_REF_CATEGORIES_SORT_ORDER);
    const [upsertRefProductCategoryMutation, { loading }] = useMothershipMutation(UPSERT_REF_PRODUCT_CATEGORY);
    const [deleteProductCategoryMutation] = useMothershipMutation(DELETE_PRODUCT_CATEGORY);

    if (!Object.keys(productsByCategories).length) {
        setSessionStorage(DIRTY_PRODUCTS, []);
    }

    // using useQuery isntead of useMothershipQuery since we had a bug with copying
    // bundle products (bundle categories have same ids which is saved and grouped in cache as one)
    const { data: getProducts } = useQuery(GET_PRODUCTS_BY_COMPANY_ID, {
        variables: { companyId: selectedCompany.id },
        fetchPolicy: "network-only"
    });

    const [loadCategories, { data: getCategories }] = useMothershipLazyQuery(GET_PRODUCT_CATEGORIES, {
        variables: { companyId: selectedCompany.id },
        fetchPolicy: "network-only"
    });

    const [loadCompanyMenus, { data: getCompanyMenusData }] = useMothershipLazyQuery(GET_REDUCED_MENUS_MODIFICATIONS, {
        variables: {
            companyId: selectedCompany.id
        },
        fetchPolicy: "network-only"
    });
    const [loadProductsInMenus, { data: getProductsIds }] = useMothershipLazyQuery(GET_PRODUCTS_IN_MENUS, {
        variables: {
            companyId: selectedCompany.id
        },
        fetchPolicy: "network-only"
    });

    const [loadMissedCategories, { data: getMissedCategories }] = useMothershipLazyQuery(
        GET_COMPANY_PRODUCT_SIE_ACCOUNTS,
        {
            variables: {
                companyId: selectedCompany.id
            }
        }
    );

    useEffect(() => {
        loadCategories();
        loadProductsInMenus();
        loadCompanyMenus();
        loadMissedCategories();
    }, []);

    useEffect(() => {
        if (
            getProductsIds?.getProductsInMenus &&
            getProducts?.getCompanyRefProducts &&
            getCategories?.getProductCategories &&
            !Object.keys(productCategories).length
        ) {
            const menusWithProducts: Set<string> = new Set(getProductsIds?.getProductsInMenus ?? []);
            setProductIdsInMenu(menusWithProducts);
            let productsByCategory = [
                ...getProducts?.getCompanyRefProducts,
                ...getProducts?.getCompanyRefBundleProducts
            ];
            const categories = getCategories?.getProductCategories;
            const sortedCat = categories.sort(
                (a: RefProductCategory, b: RefProductCategory) => a.sortOrder - b.sortOrder
            );
            setProductCategories(sortedCat);
            if (productsByCategory.length > 20) {
                const categoryIdsToCollapse: Set<string> = new Set(sortedCat.map((cat: RefProductCategory) => cat.id));
                setCollapsedCategories(categoryIdsToCollapse);
            }
            productsByCategory = productsByCategory.map((p: any) => {
                return { msProduct: p, isInMenu: menusWithProducts.has(p.id!) };
            });
            const productsByCategories = buildProductByCategories(sortedCat, productsByCategory);

            setProductsByCategories(productsByCategories);
            setSavingLoader(false);
        }
    }, [getProductsIds, getProducts, getCategories]);

    useEffect(() => {
        if (getMissedCategories?.getCategoriesMissedInSIEAccounts) {
            setMissingSIEAccountsByCategories(getMissedCategories.getCategoriesMissedInSIEAccounts);
        }
    }, [getMissedCategories]);

    useEffect(() => {
        if (Object.keys(productsByCategories).length > 0) {
            const bundleCategoriesAndProducts = productCategories?.reduce(
                (allCategories: any, currCategory: RefProductCategory) => {
                    const onlySimpleProducts =
                        //@ts-ignore
                        productsByCategories[currCategory.id].length > 0 &&
                        //@ts-ignore
                        productsByCategories[currCategory.id].filter(
                            (p: ShownProductType) => !p.msProduct.bundleProductCategories
                        );

                    if (onlySimpleProducts.length > 0) {
                        const currentCategoryWithProducts = {
                            refProductCategory: currCategory,
                            children: onlySimpleProducts
                        };
                        return [...allCategories, currentCategoryWithProducts];
                    }
                    return allCategories;
                },
                []
            );
            setBundleCategoriesData(bundleCategoriesAndProducts);
        }
    }, [productsByCategories]);

    useEffect(() => {
        if (!!selectedCompany && !!selectedCompany?.countryLocale) {
            const isSwedishCompany = selectedCompany?.countryLocale?.localeCode == CountryLocaleId.sv_SE ?? false;
            setIsSwedishCompanyAndSellsAlcohol(isSwedishCompany && selectedCompany?.settings?.sellingAlcohol);
        }
    }, [selectedCompany]);

    const saveProduct = async (products: ProductType[], modificationChanged: boolean, oldCategoryId?: string) => {
        setSavingLoader(true);

        const { bundleProductsToSave, productsToSave } = getProductsToSave(products);

        const savedProducts =
            productsToSave.length > 0
                ? await saveProductsMutation({
                      variables: { refProducts: productsToSave }
                  })
                : {};
        const savedBundleProducts =
            bundleProductsToSave.length > 0
                ? await saveBundleProductsMutation({
                      variables: { refBundleProducts: bundleProductsToSave }
                  })
                : {};

        if (savedProducts && savedBundleProducts) {
            const numProductsSaved = productsToSave?.length || 0 + bundleProductsToSave?.length || 0;
            showToastSuccess(translateWithArgument("xProductsWereSaved", numProductsSaved));
            setSessionStorage(DIRTY_PRODUCTS, []);
            let updatedProducts: any[] = [];
            if (savedProducts.data?.saveProducts) {
                updatedProducts = updatedProducts.concat(savedProducts.data.saveProducts);
            }
            if (savedBundleProducts.data?.saveBundleProducts) {
                updatedProducts = updatedProducts.concat(savedBundleProducts.data.saveBundleProducts);
            }

            if (modificationChanged && productsToSave.length === 1) {
                checkModificationChanges(productsToSave[0]);
            }
            products.length === 1 && openCollapsedCategory(products[0]);
            isNewProductEditorOpen && setIsNewProductEditorOpen(false);

            updateProductsByCategories(updateProductListOperations.SAVE, updatedProducts, -1, oldCategoryId);
            handleToggleShouldShowPrompt(false, false);
            return updatedProducts;
        } else {
            showToastError(translate("somethingWentWrong"));
        }
        return null;
    };

    const getProductsToSave = (products: ProductType[]) => {
        const storedDirtyProdicts = getSessionStorage(DIRTY_PRODUCTS);

        const notSavedProducts =
            storedDirtyProdicts.length > 0
                ? storedDirtyProdicts.filter((p: ProductType) => products.every(prod => prod.id !== p.id))
                : [];

        const allProductsToSave = [...products, ...notSavedProducts];
        const productsToSave: ProductType[] = [];
        const bundleProductsToSave: ProductType[] = [];

        allProductsToSave.forEach((p: any) => {
            const { chosen, selected, isInMenu, ...cleanedProduct } = p;
            if (cleanedProduct.bundleProductCategories) {
                bundleProductsToSave.push(cleanedProduct);
            } else {
                productsToSave.push(cleanedProduct);
            }
        });

        return { bundleProductsToSave, productsToSave };
    };

    const openCollapsedCategory = (product: ProductType) => {
        const isCollapsed = collapsedCategories.has(product.refProductCategoryId);
        if (isCollapsed) {
            collapsedCategories.delete(product.refProductCategoryId);
            const updatedCollapsedCategories = new Set(collapsedCategories);
            collapsedCategories.delete(product.refProductCategoryId);
            setCollapsedCategories(updatedCollapsedCategories);
        }
    };

    const checkModificationChanges = (product: ProductType) => {
        const categoriesWhereRefProductExists = buildModificationChangedMessage(
            getCompanyMenusData.getReducedMenusModifications,
            product
        );
        if (categoriesWhereRefProductExists) {
            infoNotification(
                translateWithArgument("modificationsHasChanged", product!.name),
                categoriesWhereRefProductExists
            );
        }
    };

    const checkOverridenModificationsGroup = (product: ProductType, modificationsGroup: null | string) => {
        return getCompanyMenusData?.getReducedMenusModifications?.flatMap((menu: Menu) =>
            categoriesWithRefProductModifications(menu, product, modificationsGroup)
        );
    };

    const updateProductsListAfterSave = (
        productsList: ProductByCategories,
        products: ProductType[],
        oldCategoryId?: string
    ) => {
        if (oldCategoryId && products.length === 1) {
            productsList[oldCategoryId] = productsList[oldCategoryId].filter(p => p.msProduct.id !== products[0].id);
        }

        products.forEach((p: ProductType) => {
            if (!(p.refProductCategoryId in productsList)) {
                productsList[p.refProductCategoryId] = [];
            }
            const productToUpdateIdx = productsList[p.refProductCategoryId].findIndex(
                (currProd: ShownProductType) => currProd.msProduct.id === p.id
            );
            const newProduct = {
                msProduct: p,
                isInMenu: productIdsInMenu.has(p.id!)
            };
            if (productToUpdateIdx >= 0) {
                productsList[p.refProductCategoryId][productToUpdateIdx] = newProduct;
            } else {
                productsList[p.refProductCategoryId].push(newProduct);
            }
        });
        return { ...productsList };
    };

    const updateProductsListAfterDelete = (productsList: ProductByCategories, productToDelete: ProductType) => {
        productsList[productToDelete.refProductCategoryId] = productsList[productToDelete.refProductCategoryId].filter(
            p => p.msProduct.id !== productToDelete.id
        );

        return { ...productsList };
    };

    const updateProductsListAfterConvert = (
        productsList: ProductByCategories,
        products: ProductType[],
        convertedProductIdx: number
    ) => {
        const productToInsert = products.find(p => !p.deleted)!;
        const newProduct = {
            msProduct: productToInsert,
            isInMenu: productIdsInMenu.has(productToInsert.id!)
        };
        productsList[productToInsert.refProductCategoryId][convertedProductIdx] = newProduct;
        return { ...productsList };
    };

    const updateProductsByCategories = (
        operation: string,
        products: ProductType[],
        convertedProductIdx = -1,
        oldCategoryId?: string
    ) => {
        let updatedProductsByCategory = {};
        switch (operation) {
            case updateProductListOperations.SAVE:
                console.log(products)
                updatedProductsByCategory = updateProductsListAfterSave(
                    { ...productsByCategories },
                    products,
                    oldCategoryId
                );
                break;
            case updateProductListOperations.DELETE:
                updatedProductsByCategory = updateProductsListAfterDelete({ ...productsByCategories }, products[0]);
                break;
            case updateProductListOperations.CONVERT:
                updatedProductsByCategory = updateProductsListAfterConvert(
                    { ...productsByCategories },
                    products,
                    convertedProductIdx
                );
                break;
        }
        setSavingLoader(false);
        setProductsByCategories(updatedProductsByCategory);
    };

    const updateCategory = async (category: RefProductCategory): Promise<RefProductCategory | null> => {
        const result = await updateCategories([category]);
        return result ? result[0] : null;
    };

    const updateCategories = async (categories: RefProductCategory[]): Promise<RefProductCategory[] | null> => {
        setSavingLoader(true);

        const updatedProductCategories = [...productCategories];
        const updatedProductsByCategories = { ...productsByCategories };
        const updatedMissingSIEAccountsByCategories = [...missingSIEAccountsByCategories];

        try {
            // Use Promise.all to ensure all categories are saved concurrently
            const results = await Promise.all(
                categories.map(async category => {
                    const res = await upsertRefProductCategoryMutation({
                        variables: { refProductCategory: category }
                    });

                    if (res.data.upsertProductCategory) {
                        const updatedCategory = res.data.upsertProductCategory;

                        // Update the products by category
                        updatedProductsByCategories[updatedCategory.id] = [];

                        // Update product categories list
                        updatedProductCategories.unshift(updatedCategory);
                        updatedMissingSIEAccountsByCategories.push(updatedCategory.id);

                        return updatedCategory;
                    }
                    return null;
                })
            );

            // Filter out any null results (in case a category failed to save)
            const successfulUpdates = results.filter(category => category !== null) as RefProductCategory[];

            if (successfulUpdates.length > 0) {
                // Recalculate sortOrder after all categories have been added
                successfulUpdates.forEach((cat, idx) => (cat.sortOrder = idx));
                saveCategorySortOrder(updatedProductCategories, "", true, true);

                // Update the states only after all categories are processed
                setProductsByCategories(updatedProductsByCategories);
                setMissingSIEAccountsByCategories(updatedMissingSIEAccountsByCategories);
            }

            return successfulUpdates;
        } catch (error) {
            console.error("Error updating categories:", error);
            return null;
        } finally {
            setSavingLoader(false);
        }
    };

    const saveCategorySortOrder = async (
        newCategories: RefProductCategory[],
        deletedcategoryName: string,
        isAddingNewCategory: boolean,
        needToUpdateAllCategories?: boolean
    ) => {
        const refProductCategoryIds = newCategories.map((cat: RefProductCategory) => cat.id);
        const res = await updateRefCategoriesSortOrder({
            variables: { refProductCategoryIds, companyId: selectedCompany.id }
        });

        if (res.data.saveCategorySortOrder) {
            needToUpdateAllCategories && setProductCategories(newCategories);
            if (!isAddingNewCategory) {
                deletedcategoryName
                    ? showToastWarning(translateWithArgument("categoryDeleted", deletedcategoryName))
                    : showToastSuccess(translate("categorySaved"));
            }
            setSavingLoader(false);
        }
    };

    const deleteRefCategory = async (refProductCategoryId: string, categoryIdx: number) => {
        setSavingLoader(true);

        const deletedCategoryName = productCategories[categoryIdx].name;
        const deleted = await deleteProductCategoryMutation({
            variables: {
                refProductCategoryId: refProductCategoryId,
                companyId: selectedCompany.id
            }
        });
        if (deleted.data.deleteProductCategory) {
            let updatedProductCategories: any = [...productCategories];
            updatedProductCategories.splice(categoryIdx, 1);
            updatedProductCategories.forEach((cat: RefProductCategory, idx: number) => (cat.sortOrder = idx));
            setProductCategories([...updatedProductCategories]);
            saveCategorySortOrder([...updatedProductCategories], deletedCategoryName, false);
            if (collapsedCategories.has(refProductCategoryId)) {
                collapsedCategories.delete(refProductCategoryId);
                setCollapsedCategories(collapsedCategories);
            }
        }
    };

    const updateProductByCategories = (updatedProductByCategories: ProductByCategories) => {
        setProductsByCategories({
            ...updatedProductByCategories
        });
    };

    const saveCategoryName = (categories: RefProductCategory[]) => {
        setSavingLoader(true);
        const updatedNamesList: string[] = [];
        const updatedProductCategories = [...productCategories];
        const categoryPromises = categories.map(async (cat: RefProductCategory) => {
            const res = await upsertRefProductCategoryMutation({
                variables: { refProductCategory: cat }
            });
            if (res.data.upsertProductCategory) {
                updatedNamesList.push(res.data.upsertProductCategory.name);
                updatedProductCategories[cat.sortOrder].name = cat.name;
                if (categories.length === updatedNamesList.length) {
                    const updatedCategoriesText = updatedNamesList.join(", ") + " " + translate("saved");
                    showToastSuccess(updatedCategoriesText);
                }
            }
        });

        return Promise.all(categoryPromises).then(values => {
            setProductCategories(updatedProductCategories);
            setDirtyCategoriesAndPrompt([]);
            setSavingLoader(false);
        });
    };

    const deleteProduct = async (product: ProductType) => {
        const res = await confirmNotification(
            translateWithArgument("deleteWithArg", product.name),
            translateWithArgument("deleteWithArgMessage", product.name)
        );
        if (res.value) {
            setSavingLoader(true);
            product.deleted = true;
            const isBundleProduct = product.bundleProductCategories;
            const deletedProductResponse = isBundleProduct
                ? await saveBundleProductsMutation({
                      variables: { refBundleProducts: [product] }
                  })
                : await saveProductsMutation({
                      variables: { refProducts: [product] }
                  });
            if (deletedProductResponse) {
                const deletedProduct = isBundleProduct
                    ? deletedProductResponse.data.saveBundleProducts[0]
                    : deletedProductResponse.data.saveProducts[0];
                const deletedProductName = deletedProduct.name;

                showToastWarning(translateWithArgument("productDeleted", deletedProductName));
                updateProductsByCategories(updateProductListOperations.DELETE, [deletedProduct]);
            } else {
                showToastError(translate("somethingWentWrong"));
            }
        }
    };
    const convertProduct = async (product: any, idx: number) => {
        const isComboProduct = product.bundleProductCategories;
        const convertMessage = isComboProduct
            ? translateWithArgument("convertedToRefProduct", product.name)
            : translateWithArgument("convertedToComboProduct", product.name);
        const res = await confirmNotification(
            translate("convertProduct"),
            convertMessage,
            `${translate("yes")}, ${translate("convert")}`
        );

        if (res.value) {
            setSavingLoader(true);
            const { refProductCategoryName, chosen, selected, isInMenu, ...cleanedProduct } = product;

            let productToDelete = { ...cleanedProduct, deleted: true };
            let productToAdd = { ...cleanedProduct, id: null };

            if (product.bundleProductCategories) {
                delete productToAdd.bundleProductCategories;
            } else {
                delete productToAdd.modifications;
                delete productToAdd.ingredients;
                productToAdd.bundleProductCategories = [];
            }
            productToAdd.priceType = PriceType.PRICE_PER_UNIT;

            const convertedProducts = await saveProductsMutation({
                variables: { refProducts: product.bundleProductCategories ? productToAdd : productToDelete }
            });

            const convertedBundleProducts = await saveBundleProductsMutation({
                variables: {
                    refBundleProducts: product.bundleProductCategories ? productToDelete : productToAdd
                }
            });

            if (convertedProducts && convertedBundleProducts) {
                showToastSuccess(translate("productWasConverted"));
                updateProductsByCategories(
                    updateProductListOperations.CONVERT,
                    [...convertedProducts.data.saveProducts, ...convertedBundleProducts.data.saveBundleProducts],
                    idx
                );
            } else {
                showToastError(translate("somethingWentWrong"));
            }
        }
    };

    const toggleCollapseAllCategories = () => {
        const updatedCollapsedCategories = new Set(collapsedCategories);
        if (updatedCollapsedCategories.size === productCategories.length) {
            updatedCollapsedCategories.clear();
            setCollapsedCategories(updatedCollapsedCategories);
        } else {
            const allCategoriesIds = new Set(productCategories.map(cat => cat.id));
            setCollapsedCategories(allCategoriesIds);
        }
    };

    const setDirtyCategoriesAndPrompt = (categories: RefProductCategory[]) => {
        setDirtyCategories(categories);
        handleToggleShouldShowPrompt(null, categories.length > 0);
    };

    const handleToggleShouldShowPrompt = (isDirtyProducts: boolean | null, isDirtyCategories: boolean | null) => {
        const isAnyDirtyProducts =
            isDirtyProducts === null ? getSessionStorage(DIRTY_PRODUCTS).length > 0 : isDirtyProducts;
        const isAnyDirtyCategories = isDirtyCategories === null ? dirtyCategories.length > 0 : isDirtyCategories;
        const dirtyProductsOrCategories = isAnyDirtyProducts || isAnyDirtyCategories;
        if (dirtyProductsOrCategories != shouldShowPrompt) {
            setShouldShowPrompt(!shouldShowPrompt);
        }
    };

    const value = {
        productsByCategories,
        productCategories,
        dirtyCategories,
        collapsedCategories,
        savingLoader,
        isNewProductEditorOpen,
        isDragAndDropProductPrevented,
        bundleCategoriesData,
        missingSIEAccountsByCategories,
        isSwedishCompanyAndSellsAlcohol,
        shouldShowPrompt,
        setIsNewProductEditorOpen,
        saveProduct,
        updateCategory,
        updateCategories,
        updateProductByCategories,
        setCollapsedCategories,
        saveCategoryName,
        deleteProduct,
        convertProduct,
        toggleCollapseAllCategories,
        saveCategorySortOrder,
        deleteRefCategory,
        setIsDragAndDropProductPrevented,
        setShouldShowPrompt,
        setDirtyCategoriesAndPrompt,
        handleToggleShouldShowPrompt,
        checkOverridenModificationsGroup
    };
    return <ProductLibraryContext.Provider value={value}>{children}</ProductLibraryContext.Provider>;
};

export const useProductLibrary = () => {
    const ctx = React.useContext(ProductLibraryContext);
    if (!ctx) {
        throw new Error("useProductLibrary must be within ProductLibraryProvider");
    }
    return ctx;
};
